import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { NavLink } from 'react-router-dom';
import { IdCell } from '../../common/cards/id-cell-card.component';
import {
  CleanDeliveryOrder,
  RbacObject,
} from '../../../export-types/cleaned-types';
import { FormattedMessage } from 'react-intl';
import { Rbac } from '../../common/Rbac';
import { PackageMethodRequestInfoActions } from './package-method-request-info-actions';
import { RequestPackageMethodEditForm } from './request-package-methods-edit-form';

type DeliveryOrderPackageGroupInfoProps = {
  id: string;
};
export const DeliveryOrderPackageGroupInfo: React.FC<
  DeliveryOrderPackageGroupInfoProps
> = (props) => {
  const [editMode, setEditMode] = React.useState(false);

  const { data: order, mutate } = useSWR<CleanDeliveryOrder>(
    `/delivery-order/${props.id}`,
    fetcher,
  );

  if (!order) {
    return null;
  }

  function handleEdit() {
    setEditMode(true);
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div className="fs-18 fw-600 color-gray-450">
          <FormattedMessage
            defaultMessage="Упаковка товаров"
            id="delivery.label.packagingOfGoods"
            description="Подзаголовок на странице"
          />
        </div>
        {order.status === 'assembly' && (
          <Rbac object={RbacObject.DeliveryOrder} action={'write:address'}>
            <PackageMethodRequestInfoActions onEditPackageMethod={handleEdit} />
          </Rbac>
        )}
      </div>
      {order.compositeDelivery && (
        <div className="color-gray-450">Отсутствует</div>
      )}
      {editMode && order.packageGroups && (
        <RequestPackageMethodEditForm
          packageMethods={order.packageMethods}
          packageGroups={order.packageGroups}
          id={order.id}
          onCancel={() => {
            setEditMode(false);
          }}
          onSave={() => {
            mutate();
            setEditMode(false);
          }}
        />
      )}
      {!editMode &&
        order.packageMethods &&
        (!order.packageGroups || order.packageGroups.length === 0) && (
          <div>
            <div className="color-gray-450">
              <FormattedMessage
                defaultMessage="Упаковка №1"
                id="delivery.label.packageNumberOne"
                description="Подзаголовок на странице"
              />
            </div>
            <table className="table table-responsive table-borderless rounded  mt-3 bg-white">
              <thead className="color-gray-600 border-bottom">
                <tr>
                  <td>{order.packageMethods}</td>
                  <td className="text-end">
                    <FormattedMessage
                      defaultMessage="Место хранения"
                      id="storage.label.storagePlace"
                      description="Информация о месте хранения"
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {order.purchaseOrders &&
                  order.purchaseOrders.map((order) => (
                    <tr>
                      <td>
                        <NavLink
                          className="btn-link cursor-pointer text-decoration-underline"
                          to={`/${
                            order.type === 'purchase' ? 'purchases' : 'parcels'
                          }/${order.id}`}
                        >
                          <IdCell
                            internalId={order.internalId}
                            type={order.type}
                          />
                        </NavLink>{' '}
                      </td>
                      <td className="text-end">{order.storageLocation}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      {!editMode &&
        order.packageGroups &&
        order.packageGroups.length > 0 &&
        order.packageGroups?.map(({ purchaseOrders, methods }, i) => (
          <div key={i}>
            <div className="color-gray-450">
              <FormattedMessage
                defaultMessage="Упаковка № {value}"
                id="delivery.label.packing"
                description="Подзаголовок на странице"
                values={{ value: i + 1 }}
              />
            </div>
            <table className="table table-responsive table-borderless rounded  mt-3 bg-white">
              <thead className="color-gray-600 border-bottom">
                <tr>
                  <td>{methods.map((method) => method.name).join(', ')}</td>
                  <td className="text-end">
                    <FormattedMessage
                      defaultMessage="Место хранения"
                      id="storage.label.storagePlace"
                      description="Информация о месте хранения"
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {purchaseOrders.map((order) => (
                  <tr>
                    <td>
                      <NavLink
                        className="btn-link cursor-pointer text-decoration-underline"
                        to={`/${
                          order.type === 'purchase' ? 'purchases' : 'parcels'
                        }/${order.id}`}
                      >
                        <IdCell
                          internalId={order.internalId}
                          type={order.type}
                        />
                      </NavLink>{' '}
                    </td>
                    <td className="text-end">{order.storageLocation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
    </div>
  );
};
