import React from 'react';
import AsyncSelect from 'react-select/async';
import { fetcher } from '../../services/api';

export type Company = {
  type: 'entrepreneur' | 'company';
  inn: string;
  name: string;
  ogrn: string;
  address: string;
  kpp?: string;
  management?: string;
};

export type User = {
  id: string;
  internalId: number | null;
  phone: string;
  name: string;
};

type InnSelectProps = {
  name: string;
  placeholder?: string;
  value: Company | null;
  onSelectCompany: (value: Company) => void;
};

function getLoader() {
  return async (inputValue: string) =>
    fetcher({
      url: '/companies',
      params: {
        take: 15,
        query: inputValue || undefined,
      },
    });
}

export const InnSelect = React.forwardRef(function (
  props: InnSelectProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  return (
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      cacheOptions
      defaultOptions
      loadOptions={getLoader()}
      formatOptionLabel={(company) => {
        return (
          <div>
            <div className="text-truncate fw-bold">{company.name}</div>
            <div className="truncate">
              ИНН: {company.inn}, {company.address}
            </div>
          </div>
        );
      }}
      isMulti={false}
      isSearchable={true}
      onChange={(value) => {
        if (value) {
          props.onSelectCompany(value);
        }
      }}
      defaultValue={props.value}
      //@ts-ignore
      ref={ref}
    />
  );
});
