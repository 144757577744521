import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// @ts-ignore
import { useBranch, useRoot } from 'baobab-react/hooks';
import { AuthRouter } from './auth-router';
import { SideMenu } from './common/side-menu.component';
import { state } from '../state';
import { ProfileService } from '../services/profile.service';
import { Modals } from './common/modal.component';
import {
  userIsAdmin,
  userIsClient,
  userIsPurchase,
  userIsWarehouse,
} from '../utils/common.utils';
import { AppLoadingBar } from './app-loading-bar';
import { setSentryUser } from '../sentry';
import { UnifiedAppRouter } from './unified-app-router';
import { ConversationAlerts } from './screens/conversations/conversation-alerts';
import { CleanProfile } from '../export-types/cleaned-types';
import { IntlProvider } from 'react-intl';
import MyLoader from './common/loader.component';
import { ResolvedIntlConfig } from '@formatjs/intl/src/types';
import { useSelectLanguage } from './common/SelectLanguage';
import en from '../lang/en.json';
import zh from '../lang/zh.json';
import { NewMessagesProvider } from '../api/conversation';
import { TerminalRouter } from './terminal-router';
import { TerminalMenu } from './common/terminal-menu';
import { useMode } from '../utils/mode';
import { trpcSwr } from '../api/trpc';

const messages: Record<string, ResolvedIntlConfig['messages']> = {
  en,
  zh,
};

const App = () => {
  const { user }: { user: CleanProfile | undefined } = useBranch({
    user: 'user',
  });

  const [mode] = useMode();
  const router = React.useMemo(() => {
    if (!user || userIsClient(user)) {
      return <AuthRouter userIsClient={userIsClient(user)} />;
    }

    if (mode === 'terminal') {
      return <TerminalRouter />;
    }

    const isAdmin = userIsAdmin(user);
    const isPurchase = userIsPurchase(user);
    const isWarehouse = userIsWarehouse(user);

    const router = (
      <UnifiedAppRouter
        user={user}
        isAdmin={isAdmin}
        isPurchase={isPurchase}
        isWarehouse={isWarehouse}
      />
    );

    return (
      <div className="app-container h-100">
        {router}
        <ConversationAlerts user={user} />
      </div>
    );
  }, [mode, user]);

  const [locale] = useSelectLanguage();

  return (
    <IntlProvider
      messages={messages[locale]}
      key={locale}
      locale={locale}
      defaultLocale="ru"
    >
      <NewMessagesProvider userId={user?.id} key={user?.id}>
        <BrowserRouter>
          {mode === 'desktop' ? <SideMenu user={user} /> : <TerminalMenu />}
          <AppLoadingBar height={mode === 'desktop' ? 3 : 7} />
          {router}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Modals />
        </BrowserRouter>
      </NewMessagesProvider>
    </IntlProvider>
  );
};

const Root = ({ store }: { store: any }) => {
  const [loaded, setLoaded] = useState(false);
  const Root = useRoot(store);
  const [client] = useState(() => trpcSwr.createClient());

  useEffect(() => {
    ProfileService.getCurrentUser()
      .then((user: any) => {
        setSentryUser({
          id: user.id,
          internalId: user.internalId,
        });
      })
      .finally(() => {
        setLoaded(true);
      });
  }, []);

  return (
    <Root>
      {loaded ? (
        <trpcSwr.Provider client={client}>
          <App />
        </trpcSwr.Provider>
      ) : (
        <MyLoader />
      )}
    </Root>
  );
};

const Store = () => <Root store={state} />;

export default Store;
