import React, { useState } from 'react';
import { Input } from '../../common/input.component';
import { Select } from '../../common/select.component';
import { ROLES as USER_ROLES } from '../../../consts/db-values';
import { Checkbox } from '../../common/checkbox';
import { UserService } from '../../../services/user.service';
import { AlertService } from '../../../services/alert.service';
import { ModalProps } from '../../../services/modals.service';
import { KeyedMutator } from 'swr';
import { useSuppliers } from '../../../api/suppliers';
import { UserProps } from './users-screen.component';
import Toggle from 'rsuite/Toggle';
import { useModalClose } from '../../../utils/useModalClose';
import classNames from 'classnames';
import { useRbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';

const ROLES = Object.keys(USER_ROLES).map((key) => ({
  label: USER_ROLES[key as keyof typeof USER_ROLES],
  value: key,
}));

interface AddUserModalProps extends ModalProps {
  user?: UserProps;
  mutate: KeyedMutator<any>;
}

export const AddUserModal = ({ user, close, mutate }: AddUserModalProps) => {
  const { data: suppliers } = useSuppliers();
  const isEdit = !!user;
  const [name, setName] = useState(user?.name || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [email, setEmail] = useState(user?.email || '');
  const [role, setRole] = useState(user?.role || '');
  const [autoSend, setAutoSend] = useState(user?.autoSend || false);
  const [moderate, setModerate] = useState(user?.moderate || false);
  const [moderateDeliveryOrder, setModerateDeliveryOrder] = useState(
    user?.moderateDeliveryOrder || false,
  );

  const [password, setPassword] = useState('');
  const [purchase, setPurchase] = useState(
    user?.accesses?.includes('purchase') || false,
  );
  const [warehouse, setWarehouse] = useState(
    user?.accesses?.includes('warehouse') || false,
  );
  const [salesManager, setSalesManager] = useState(
    user?.accesses?.includes('salesManager') || false,
  );
  const [supplierFinances, setSupplierFinances] = useState(
    user?.accesses?.includes('supplierFinances') || false,
  );
  const [disableEmails, setDisableEmails] = useState(
    user?.disableEmails || false,
  );
  const [marketer, setMarketer] = useState(
    user?.accesses?.includes('marketer') || false,
  );
  const [transit, setTransit] = useState(
    user?.accesses?.includes('transit') || false,
  );

  const [financier, setFinancier] = useState(
    user?.accesses?.includes('financier') || false,
  );

  const [reader, setReader] = useState(
    user?.accesses?.includes('reader') || false,
  );
  const [supplier, setSupplier] = useState(user?.supplier?.id);

  const [limit, setLimit] = useState(user?.purchaseLimit || null);

  const [writeOffLimit, setWriteOffLimit] = useState(
    user?.autoWriteOffLimit || 200,
  );

  const [serviceMailing, setServiceMailing] = useState(user?.serviceMailing);

  const [promotionMailing, setPromotionMailing] = useState(
    user?.promotionMailing,
  );

  const [supplierClient, setSupplierClient] = useState(
    user?.defaultSupplierId || null,
  );

  const { hasPermission } = useRbac();
  const hasFullWritePermission = hasPermission(RbacObject.User, 'write:admin');

  function saveUser() {
    const userObj = {
      name,
      phone,
      email,
      role,
      password,
      accesses: [] as Array<string>,
      disableEmails,
      supplierId: supplier,
      autoSend,
      moderate,
      moderateDeliveryOrder,
      purchaseLimit: limit,
      autoWriteOffLimit: writeOffLimit,
      serviceMailing,
      promotionMailing,
      defaultSupplierId: supplierClient,
    };

    if (userObj.role === 'employee') {
      if (purchase) {
        userObj.accesses.push('purchase');
      }
      if (warehouse) {
        userObj.accesses.push('warehouse');
      }
      if (salesManager) {
        userObj.accesses.push('salesManager');
      }
      if (supplierFinances) {
        userObj.accesses.push('supplierFinances');
      }
      if (marketer) {
        userObj.accesses.push('marketer');
      }
      if (transit) {
        userObj.accesses.push('transit');
      }
      if (financier) {
        userObj.accesses.push('financier');
      }
      if (reader) {
        userObj.accesses.push('reader');
      }
      // todo: проверять что выбрана хотя бы одна роль
    }

    const promise = isEdit
      ? UserService.editUser(user.id, userObj)
      : UserService.createUser(userObj);

    promise.then(() => {
      AlertService.success();
      mutate();
      close();
    });
  }

  const modalRef = useModalClose(close);
  console.log('moderateDeliveryOrder', moderateDeliveryOrder);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <div className="modal-content">
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>{isEdit ? 'Редактировать' : 'Добавить'} пользователя</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Select
            title="Роль"
            className="mb-3"
            options={ROLES}
            value={role}
            placeholder=" "
            required
            onChange={({ target: { value } }) => setRole(value)}
            disabled={!hasFullWritePermission}
          />
          {role === 'employee' && (
            <div className="mb-3">
              <div className="mb-2">
                Права <span className="color-red">*</span>
              </div>
              <div className="d-flex flex-row flex-wrap justify-content-between">
                <Checkbox
                  title="Закупка"
                  className="me-3"
                  checked={purchase}
                  onChange={({ target: { checked } }) => setPurchase(checked)}
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Склад"
                  className="me-3"
                  checked={warehouse}
                  onChange={({ target: { checked } }) => setWarehouse(checked)}
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Склад: транзит"
                  checked={transit}
                  onChange={({ target: { checked } }) => setTransit(checked)}
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Менеджер по продажам"
                  checked={salesManager}
                  onChange={({ target: { checked } }) =>
                    setSalesManager(checked)
                  }
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Поставщик: финансы"
                  checked={supplierFinances}
                  onChange={({ target: { checked } }) =>
                    setSupplierFinances(checked)
                  }
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Маркетолог"
                  checked={marketer}
                  onChange={({ target: { checked } }) => setMarketer(checked)}
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Финансит"
                  checked={financier}
                  onChange={({ target: { checked } }) => setFinancier(checked)}
                  disabled={!hasFullWritePermission}
                />
                <Checkbox
                  title="Читатель"
                  checked={reader}
                  onChange={({ target: { checked } }) => setReader(checked)}
                  disabled={!hasFullWritePermission}
                />
              </div>
            </div>
          )}
          <Input
            title="Имя"
            className="mb-3"
            value={name}
            required
            onChange={({ target: { value } }) => setName(value)}
            disabled={!hasFullWritePermission}
          />
          <Input
            title="Номер телефона"
            className="mb-3"
            placeholder="+7"
            required
            onChange={({ target: { value } }) => setPhone(value)}
            value={phone}
            mask="+7 (999) 999-99-99"
            disabled={!hasFullWritePermission}
          />
          <Input
            title="Email"
            className="mb-3"
            onChange={({ target: { value } }) => setEmail(value)}
            value={email}
            type="email"
            disabled={!hasFullWritePermission}
          />
          {role && role !== 'client' && (
            <Checkbox
              title="Отключить email уведомления"
              className="me-3"
              checked={disableEmails}
              onChange={({ target: { checked } }) => setDisableEmails(checked)}
              disabled={!hasFullWritePermission}
            />
          )}
          <Input
            title="Пароль"
            className="mb-3"
            onChange={({ target: { value } }) => setPassword(value)}
            value={password}
            type="password"
            disabled={!hasFullWritePermission}
          />
          {role && role === 'client' && (
            <Select
              title="Привязанный поставщик"
              placeholder="Не выбрано"
              className="mb-3"
              options={
                suppliers?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })) ?? []
              }
              withEmpty
              value={supplierClient}
              onChange={({ target: { value } }) => setSupplierClient(value)}
            />
          )}

          <div className="flex d-flex">
            <div className="flex-column">
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={autoSend}
                    onChange={(val) => setAutoSend(val)}
                  />
                  <div className="ms-2">Авто-отправка</div>
                </div>
              ) : null}
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={moderate}
                    onChange={(val) => setModerate(val)}
                  />
                  <div className="ms-2">Модерация выкупов</div>
                </div>
              ) : null}
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={moderateDeliveryOrder}
                    onChange={(val) => setModerateDeliveryOrder(val)}
                  />
                  <div className="ms-2">Модерация доставок</div>
                </div>
              ) : null}
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={!!limit}
                    onChange={(val) => setLimit(val ? '100' : null)}
                  />
                  <div className="ms-2">Лимит заказа</div>
                </div>
              ) : null}
            </div>
            <div className="flex-column ps-2">
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={promotionMailing}
                    onChange={(val) => setPromotionMailing(val)}
                  />
                  <div className="ms-2">Отправка рекламы</div>
                </div>
              ) : null}
              {role === 'client' ? (
                <div className="d-flex mb-3">
                  <Toggle
                    checked={serviceMailing}
                    onChange={(val) => setServiceMailing(val)}
                  />
                  <div className="ms-2">Отправка сервисных писем</div>
                </div>
              ) : null}
            </div>
          </div>
          {role === 'client' && limit ? (
            <div className="d-flex flex-row mb-3 justify-content-between">
              {['50', '100', '500', '1000'].map((value) => (
                <button
                  onClick={() => setLimit(value)}
                  className={classNames(
                    limit === value ? 'btn-success' : 'btn-light',
                    'btn',
                  )}
                >
                  {value}
                </button>
              ))}
            </div>
          ) : null}
          {role === 'client' ? (
            <Input
              title="Лимит автосписания"
              className="mb-3"
              onChange={({ target: { value } }) => setWriteOffLimit(value)}
              value={writeOffLimit}
              type="number"
            />
          ) : null}
          {role === 'employee' ? (
            <Select
              title="Поставщик"
              placeholder="Выберите поставщика"
              className="mb-3"
              options={
                suppliers?.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })) ?? []
              }
              withEmpty
              value={supplier}
              onChange={({ target: { value } }) => setSupplier(value)}
              disabled={!hasFullWritePermission}
            />
          ) : null}
        </div>
        <div className="modal-footer">
          <div className="d-flex flex-row justify-content-end">
            <div className="btn btn-success" onClick={saveUser}>
              {isEdit ? 'Сохранить' : 'Добавить'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
