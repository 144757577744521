import React from 'react';
import { NavLink } from 'react-router-dom';
import { StatusBlock } from '../../common/status-block';
import moment from 'moment';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { FormattedMessage, useIntl } from 'react-intl';

type OrderStatusTableProps = {
  order: CleanPurchaseOrder;
};

export const AdminOrderStatusTable: React.FC<OrderStatusTableProps> = ({
  order,
}) => {
  const intl = useIntl();

  return (
    <ul className="d-flex flex-column p-0 mb-0">
      <StatusBlock
        title={intl.formatMessage({
          defaultMessage: 'Создан',
          id: 'order.status-label.created',
          description: 'Статус заказа',
        })}
        subtitle={moment(order.createdAt).format('DD.MM.YYYY, HH:mm')}
        status={'done'}
        connector={true}
        key={'createdAt'}
      />

      <StatusBlock
        title={
          order.status === 'waitingForPayment' ? (
            <FormattedMessage
              defaultMessage="Ожидание оплаты"
              id="order.status-label.awaitingPayment"
              description="Статус заказа"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Оплачен"
              id="order.status-label.paidOff"
              description="Статус заказа"
            />
          )
        }
        subtitle={
          order.paidAt && moment(order.paidAt).format('DD.MM.YYYY, HH:mm')
        }
        status={
          order.status === 'waitingForPayment'
            ? 'active'
            : order.paidAt
              ? 'done'
              : undefined
        }
        connector={true}
        key={'paidAt'}
      />

      {order.status !== 'canceled' && (
        <StatusBlock
          title={
            order.status === 'inWork' ? (
              <FormattedMessage
                defaultMessage="Выкупаем"
                id="order.status-label.redeeming"
                description="Статус заказа"
              />
            ) : order.status === 'frozen' ? (
              <FormattedMessage
                defaultMessage="Заказ заморожен"
                id="order.status-label.orderFrozen"
                description="Статус заказа"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Заказ выкуплен"
                id="order.status-label.orderRedeemed"
                description="Статус заказа"
              />
            )
          }
          subtitle={
            order.status === 'frozen'
              ? order.freezingReason
              : order.purchaseDate &&
                moment(order.purchaseDate).format('DD.MM.YYYY, HH:mm')
          }
          status={
            order.status === 'inWork' || order.status === 'frozen'
              ? 'active'
              : order.purchaseDate
                ? 'done'
                : undefined
          }
          connector={true}
          key={'purchaseDate'}
        />
      )}

      {order.status !== 'canceled' && (
        <StatusBlock
          title={
            order.status === 'onTheWayToStock' ? (
              <FormattedMessage
                defaultMessage="В пути на склад в Китай"
                id="order.status-label.inTransitToAWarehouseInChina"
                description="Статус заказа"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Прибыл на склад в Китае"
                id="order.status-label.arrivedAtWarehouseInChina"
                description="Статус заказа"
              />
            )
          }
          subtitle={
            order.receiptDate &&
            moment(order.receiptDate).format('DD.MM.YYYY, HH:mm')
          }
          status={
            order.status === 'onTheWayToStock'
              ? 'active'
              : order.receiptDate
                ? 'done'
                : undefined
          }
          connector={true}
          key={'receiptDate'}
        />
      )}

      {order.status !== 'canceled' && (
        <StatusBlock
          title={
            order.status === 'receivedInStock' ? (
              <FormattedMessage
                defaultMessage="Готов к отправке в Россию"
                id="order.status-label.readyForShipmentToRussia"
                description="Статус заказа"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Отправлен в Россию"
                id="order.status-label.shippedToRussia"
                description="Статус заказа"
              />
            )
          }
          subtitle={
            order.deliveryOrder ? (
              <>
                <span>
                  <FormattedMessage
                    defaultMessage="Доставка — "
                    id="parcelStatus.status-label.shipping"
                    description="Подзаголовок на странице"
                  />
                </span>
                <NavLink
                  className="btn-link cursor-pointer text-decoration-underline"
                  to={`/deliveries/delivery-order/${order.deliveryOrder.id}`}
                >{`D${order.deliveryOrder.internalId}`}</NavLink>
              </>
            ) : undefined
          }
          status={
            order.status === 'receivedInStock'
              ? 'active'
              : order.status === 'sentToRussia'
                ? 'done'
                : undefined
          }
          connector={false}
          key={'sentToRussia'}
        />
      )}

      {order.status === 'canceled' && (
        <StatusBlock
          title={
            <FormattedMessage
              defaultMessage="Отменен"
              id="order.status-label.canceled"
              description="Статус заказа"
            />
          }
          subtitle={
            <>
              {order.cancelledAt && (
                <div>
                  {moment(order.cancelledAt).format('DD.MM.YYYY, HH:mm')}
                </div>
              )}
              <div>{order.cancelReason}</div>
            </>
          }
          status={'canceled'}
          connector={false}
          key={'canceled'}
        />
      )}
    </ul>
  );
};
