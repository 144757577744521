import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import targetIcon from '../../assets/target.svg';
import React from 'react';
import { Rbac, RbacSome } from './Rbac';
import { RbacObject } from '../../export-types/cleaned-types';
import { useUnreadMessages } from '../../api/conversation';
import { useMode } from '../../utils/mode';

type User = {
  role: string;
  accesses: Array<string>;
};

type Props = {
  user?: User;
};

export const SideMenu: React.FC<Props> = ({ user }) => {
  const [, setMode] = useMode();
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split('/');
  const { data } = useUnreadMessages(!!user);
  function onClickBack() {
    navigate('/' + paths[1]);
  }

  function isTabs() {
    if (paths[1] === 'purchases') {
      let pathNumber;
      if (paths[2] === 'tariffs' || paths[2] === 'commissions') return true;
      if (paths[2] === 'orders') {
        pathNumber = paths[3];
      } else {
        pathNumber = paths[2];
      }
      switch (pathNumber) {
        case 'waiting-for-payment':
        case 'in-work':
        case 'on-the-way':
        case 'received-in-stock':
        case 'archive':
        case 'frozen':
          return true;
        default:
          return false;
      }
    }

    if (paths[1] === 'deliveries') {
      let pathNumberDel;
      if (
        paths[2] === 'delivery' ||
        paths[2] === 'package' ||
        paths[2] === 'markups'
      )
        return true;
      if (paths[2] === 'orders') {
        pathNumberDel = paths[3];
      } else {
        pathNumberDel = paths[2];
      }
      switch (pathNumberDel) {
        case 'processing':
        case 'assembly':
        case 'sent-to-russia':
        case 'received-in-russia':
        case 'sent-to-recipient':
        case 'delivered':
          return true;
        default:
          return false;
      }
    }

    if (paths[1] === 'parcels') {
      let pathNumberParcel = paths[2];

      switch (pathNumberParcel) {
        case 'on-the-way':
        case 'received-in-stock':
        case 'archive':
          return true;
        default:
          return false;
      }
    }

    if (paths[1] === 'finances-admin') {
      return true;
    }

    if (paths[1] === 'suppliers') {
      return true;
    }

    return false;
  }

  const LogoImage = <img width="48px" height="48px" src={Logo} alt="" />;
  const ReturnBackImage = (
    <div
      style={{ fontSize: 50 }}
      className="bi bi-arrow-left-circle-fill color-gray-100 lh-1"
    />
  );

  return (
    <div
      className={`side-menu flex-column justify-content-between${
        user ? ' active d-flex' : ' d-none d-sm-flex'
      }`}
    >
      {paths.length > 2 && !isTabs() ? (
        <div
          className="w-100 d-flex justify-content-center align-items-center hover-opacity cursor-pointer"
          onClick={onClickBack}
        >
          {ReturnBackImage}
        </div>
      ) : (
        <div className="w-100 d-flex justify-content-center align-items-center">
          {LogoImage}
        </div>
      )}

      {!user && (
        <div>
          <div className="side-menu_empty_element" />
          <div className="side-menu_empty_element" />
          <div className="side-menu_empty_element" />
        </div>
      )}
      {user && (
        <div className="scroll overflow-auto">
          <Whisper
            placement="right"
            trigger="hover"
            speaker={<Tooltip>Диалоги</Tooltip>}
          >
            <NavLink
              to="/conversations"
              className={({ isActive }) =>
                `position-relative side-menu_element${
                  isActive ? ' active' : ''
                }`
              }
            >
              <i className="bi bi-chat"></i>
              {(data?.unread ?? 0) > 0 && (
                <span
                  className="position-absolute top-0 end-0 badge bg-green-500"
                  style={{ fontSize: '1rem' }}
                >
                  {data?.unread}
                </span>
              )}
            </NavLink>
          </Whisper>
          <Rbac object={RbacObject.User} action="read:admin">
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Пользователи</Tooltip>}
            >
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi-people" />
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac object={RbacObject.Purchase} action={'read'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Выкупы</Tooltip>}
            >
              <NavLink
                to="/purchases"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi-box-seam" />
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac object={RbacObject.Parcel} action={'read'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Входящие посылки</Tooltip>}
            >
              <NavLink
                to="/parcels"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi bi-inbox" />
              </NavLink>
            </Whisper>
          </Rbac>

          <RbacSome
            checks={[
              { object: RbacObject.DeliveryOrder, action: 'read' },
              { object: RbacObject.DeliveryOrder, action: 'read:transit' },
            ]}
          >
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Доставки</Tooltip>}
            >
              <NavLink
                to="/deliveries"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi-truck" />
              </NavLink>
            </Whisper>
          </RbacSome>

          <Rbac object={RbacObject.CompositeDeliveryOrder} action={'read'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Транзитные посылки</Tooltip>}
            >
              <NavLink
                to="/composite-deliveries"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi-boxes" />
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac
            object={RbacObject.Transaction}
            action={['read:admin', 'read:supplier']}
          >
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Финансы</Tooltip>}
            >
              <NavLink
                to="/finances-admin"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi-credit-card" />
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac object={RbacObject.Story} action={'read:admin'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Маркетинг</Tooltip>}
            >
              <NavLink
                to="/marketing"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <img
                  src={targetIcon}
                  className=""
                  width="32px"
                  height="32px"
                  alt=""
                />
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac object={RbacObject.AuditLog} action={'read'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Лог изменений</Tooltip>}
            >
              <NavLink
                to="/audit-log"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi bi-list-columns-reverse"></i>
              </NavLink>
            </Whisper>
          </Rbac>

          <Rbac object={RbacObject.Supplier} action={'read:admin'}>
            <Whisper
              placement="right"
              trigger="hover"
              speaker={<Tooltip>Поставщики</Tooltip>}
            >
              <NavLink
                to="/suppliers"
                className={({ isActive }) =>
                  `side-menu_element${isActive ? ' active' : ''}`
                }
              >
                <i className="bi bi-globe"></i>
              </NavLink>
            </Whisper>
          </Rbac>
          <Whisper
            placement="right"
            trigger="hover"
            speaker={<Tooltip>Режим ТСД</Tooltip>}
          >
            <NavLink
              to="/terminal"
              className={({ isActive }) =>
                `side-menu_element${isActive ? ' active' : ''}`
              }
              onClick={(e) => {
                e.preventDefault();
                setMode('terminal');
              }}
            >
              <i className="bi-tablet" />
            </NavLink>
          </Whisper>
        </div>
      )}
      <div />
    </div>
  );
};
