import React, { useRef } from 'react';
import {
  PurchaseOrderService,
  usePurchaseOrderTariffs,
} from '../../../services/purchase-order.service';
import Table, { CellProps } from 'rsuite/Table';
import { toMoney } from '../../../utils/common.utils';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import Toggle from 'rsuite/Toggle';
import { ModalsService } from '../../../services/modals.service';
import { AlertService } from '../../../services/alert.service';
import { PurchaseOrderDeliveryTariffModal } from './purchase-order-delivery-tariff-modal.component';
import {
  CleanDeliveryOrderDeliveryMarkup,
  CleanPurchaseOrderDeliveryTariff,
} from '../../../export-types/cleaned-types';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import { SupplierCell } from '../../common/table/supplier-cell';

export const PurchaseOrderDeliveryTariffsTab = () => {
  const { data: tariffs, isLoading, mutate } = usePurchaseOrderTariffs();

  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  function setDisable(id: string, disabled: boolean) {
    PurchaseOrderService.updateTariff(id, { disabled }).then(() => {
      mutate();
      AlertService.success();
    });
  }

  const PriceCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanPurchaseOrderDeliveryTariff>) =>
    rowData && (
      <Table.Cell {...props}>
        {toMoney(
          rowData[dataKey as keyof CleanPurchaseOrderDeliveryTariff] as string,
          rowData?.supplier?.purchaseCurrency ?? 'cny',
        )}
      </Table.Cell>
    );

  const MarkupCell = ({
    rowData,
    ...props
  }: CellProps<CleanPurchaseOrderDeliveryTariff>) =>
    rowData && <Table.Cell {...props}>{rowData.markup} %</Table.Cell>;

  const DisableCell = ({
    rowData,
    ...props
  }: CellProps<CleanPurchaseOrderDeliveryTariff>) =>
    rowData && (
      <Table.Cell {...props}>
        <Toggle
          checked={!rowData.disabled}
          onChange={(val) => setDisable(rowData.id, !val)}
        />
      </Table.Cell>
    );

  const renderMenu =
    (tariff: CleanDeliveryOrderDeliveryMarkup): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = (eventKey: number | string | undefined) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
          onClose();
          switch (eventKey) {
            case 1:
              ModalsService.createModal(PurchaseOrderDeliveryTariffModal, {
                tariff,
                title: 'Редактировать тариф на доставку',
              })
                .then((tariff) => {
                  return PurchaseOrderService.updateTariff(tariff.id, tariff);
                })
                .then(() => {
                  mutate();
                  AlertService.success();
                });
              return;
            case 2:
              const result = window.confirm(
                `Вы действительно хотите удалить тариф на доставку «${toMoney(
                  tariff.priceTo,
                  'cny',
                )} / ${tariff.markup}%»?`,
              );
              if (!result) {
                return;
              }

              PurchaseOrderService.deleteTariff(tariff.id).then(() => {
                mutate();
                AlertService.success();
              });
              return;
            default:
              return;
          }
        }, 100);
      };
      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={1}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={2}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  const ActionCell = ({
    rowData,
    dataKey,
    ...props
  }: CellProps<CleanPurchaseOrderDeliveryTariff>) => {
    return (
      rowData && (
        <Table.Cell
          {...props}
          className="link-group no-padding-cell pt-2"
          align="right"
        >
          <Whisper
            placement="leftStart"
            trigger="click"
            speaker={renderMenu(rowData)}
          >
            <IconButton
              appearance="subtle"
              icon={<i className="bi bi-three-dots" />}
            />
          </Whisper>
        </Table.Cell>
      )
    );
  };

  return (
    <Table data={tariffs} loading={isLoading} headerHeight={57} autoHeight>
      <Table.Column width={150}>
        <Table.HeaderCell>Поставщик</Table.HeaderCell>
        <SupplierCell />
      </Table.Column>
      <Table.Column flexGrow={1} minWidth={120}>
        <Table.HeaderCell>Сумма заказа до</Table.HeaderCell>
        <PriceCell dataKey="priceTo" />
      </Table.Column>
      <Table.Column flexGrow={1} minWidth={120}>
        <Table.HeaderCell>Стоимость доставки</Table.HeaderCell>
        <MarkupCell />
      </Table.Column>
      <Table.Column flexGrow={1} minWidth={100}>
        <Table.HeaderCell> </Table.HeaderCell>
        <DisableCell />
      </Table.Column>
      <Table.Column>
        <Table.HeaderCell> </Table.HeaderCell>
        <ActionCell />
      </Table.Column>
    </Table>
  );
};
