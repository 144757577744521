import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, CustomProvider, DatePicker } from 'rsuite';
import { ruRU } from 'rsuite/locales';
import { ModalProps } from '../../../services/modals.service';
import { useSuppliers } from '../../../api/suppliers';
import { Select } from '../../common/select.component';
import { required } from '../../../utils/validations';
import { useModalClose } from '../../../utils/useModalClose';
import {
  getProfitReportUrl,
  ProfitReportUrlParams,
} from '../../../utils/common.utils';
import { InputWrapper } from '../../common/input-wrapper';
import {
  formatDateValue,
  getCurrentDateValue,
  parseRawDateValue,
  RawDateValue,
} from '../../../utils/date-value';
import { isCorrectDate } from './reconciliation-act-modal';
import { periods, Period, useReportLink } from './useReportLink';

type ReportItem = {
  value: string;
  type: string;
};
const REPORT: ReportItem[] = [
  { value: 'Выкупы (оплаченные, но не выкупленные)', type: 'unpaid' },
  {
    value: 'Доход с выкупленных заказов (без комиссий и доп расходов)',
    type: 'without-commission',
  },
  { value: 'Доход с выкупленных заказов', type: 'full' },
  { value: 'Статистика по выкупам', type: 'statistics' },
];

type Form = {
  suppliers: number[];
  period: Period;
  start?: RawDateValue;
  end?: RawDateValue;
};

type Props = ModalProps & {
  kind: 'unpaid' | 'without-commission' | 'full' | 'statistics';
};

export const ProfitReportFromDiscountsModal: React.FC<Props> = ({
  close,
  kind,
}) => {
  const { data: allSuppliers } = useSuppliers();

  const {
    control,
    formState: { isValid, errors },
    watch,
    getValues,
    trigger,
  } = useForm<Form>({
    mode: 'all',
    defaultValues: {
      period: 'all_time',
    },
  });

  const modalRef = useModalClose(close);

  const [suppliers, period, customStart, customEnd] = watch([
    'suppliers',
    'period',
    'start',
    'end',
  ]);
  React.useEffect(() => {
    trigger(['start', 'end']);
  }, [trigger, customStart, customEnd]);

  const minDate = React.useMemo(
    () =>
      formatDateValue(
        getCurrentDateValue().subtract(1, 'year').startOf('year'),
      ),
    [],
  );
  const maxDate = React.useMemo(
    () => formatDateValue(getCurrentDateValue()),
    [],
  );

  const params: ProfitReportUrlParams = {
    kind,
    suppliers,
  };

  const reportLink = useReportLink(
    isValid,
    period,
    params,
    //@ts-expect-error
    getProfitReportUrl as (params: getReconciliationActUrl) => string,
    customStart,
    customEnd,
  );
  const title = REPORT.find((item) => item.type === kind)?.value;

  return (
    <CustomProvider locale={ruRU}>
      <div className="modal-dialog" ref={modalRef}>
        <form className="modal-content">
          <div className="modal-body">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h3>
                {title ? title : 'Отчет о прибыли со скидки в разрезе партнёра'}
              </h3>
              <div className="btn p-0" onClick={close}>
                <i className="bi bi-x fs-36 color-gray-600" />
              </div>
            </div>
            <Controller
              name="suppliers"
              control={control}
              rules={required}
              render={({ field }) => (
                <Select
                  className="mb-3"
                  title="Поставщик"
                  placeholder="Выберите поставщика"
                  isMulti
                  required
                  options={
                    allSuppliers?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) ?? []
                  }
                  onChange={(selectedOption) => field.onChange(selectedOption)}
                  value={field.value}
                  error={errors.suppliers?.message}
                />
              )}
            />
            <Controller
              name="period"
              control={control}
              rules={{
                required: 'Укажите период',
              }}
              render={({ field, fieldState }) => {
                return (
                  <InputWrapper
                    required
                    title="Выберите период"
                    error={fieldState.error?.message}
                    className="mb-3"
                  >
                    <select className="form-select" {...field}>
                      {periods.map((item) => {
                        return (
                          <option
                            value={item.id}
                            key={item.id}
                            className="mb-1 cursor-pointer bg-white py-3 pl-3 pr-7 text-base-lh-125 font-medium text-gray-800 hover:bg-green-100"
                          >
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                  </InputWrapper>
                );
              }}
            />
            {period === 'range' && (
              <div className="w-full sm:flex sm:items-start sm:justify-between">
                <Controller
                  name="start"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      if (!isCorrectDate(value))
                        return 'Укажите корректную дату';

                      const end = getValues('end');

                      if (!end || !isCorrectDate(end)) return true;

                      if (
                        parseRawDateValue(value).isAfter(parseRawDateValue(end))
                      )
                        return 'Дата не должна быть позже окончания';

                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Дата начала"
                        error={fieldState.error?.message}
                        className="mb-2 flex-1 text-base-lh-100 sm:mb-0 sm:mr-4"
                      >
                        <DatePicker
                          className={'w-100'}
                          format={'dd.MM.yyyy'}
                          isoWeek={true}
                          shouldDisableDate={(d) => {
                            const fieldDate = customEnd
                              ? parseRawDateValue(customEnd).toDate()
                              : undefined;
                            if (fieldDate) {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > fieldDate
                              );
                            } else {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            }
                          }}
                          onChange={(v) => {
                            if (v === null) {
                              field.onChange(null);
                            } else {
                              field.onChange(formatDateValue(v));
                            }
                          }}
                          value={
                            field.value
                              ? parseRawDateValue(field.value).toDate()
                              : undefined
                          }
                        />
                      </InputWrapper>
                    );
                  }}
                />
                <Controller
                  name="end"
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) return true;

                      if (!isCorrectDate(value))
                        return 'Укажите корректную дату';

                      const start = getValues('start');

                      if (!start || !isCorrectDate(start)) return true;

                      if (
                        parseRawDateValue(value).isBefore(
                          parseRawDateValue(start),
                        )
                      )
                        return 'Дата не должна быть раньше начальной';

                      return true;
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <InputWrapper
                        title="Дата окончания"
                        error={fieldState.error?.message}
                        className="mb-2 flex-1 text-base-lh-100 sm:mb-0"
                      >
                        <DatePicker
                          className={'w-100'}
                          shouldDisableDate={(d) => {
                            const fieldDate = customStart
                              ? parseRawDateValue(customStart).toDate()
                              : undefined;
                            if (fieldDate) {
                              return (
                                d < fieldDate ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            } else {
                              return (
                                d < parseRawDateValue(minDate).toDate() ||
                                d > parseRawDateValue(maxDate).toDate()
                              );
                            }
                          }}
                          format={'dd.MM.yyyy'}
                          isoWeek={true}
                          onChange={(v) => {
                            if (v === null) {
                              field.onChange(null);
                            } else {
                              field.onChange(formatDateValue(v));
                            }
                          }}
                          value={
                            field.value
                              ? parseRawDateValue(field.value).toDate()
                              : undefined
                          }
                        />
                      </InputWrapper>
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="modal-footer justify-content-end">
            {reportLink ? (
              <Button
                as="a"
                href={reportLink}
                target="_blank"
                className="btn btn-success"
              >
                Скачать в Excel
              </Button>
            ) : (
              <Button as="button" disabled={true} className="btn btn-success">
                Скачать в Excel
              </Button>
            )}
          </div>
        </form>
      </div>
    </CustomProvider>
  );
};
