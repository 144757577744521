import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import Pagination from 'rsuite/Pagination';
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom';
import { api } from '../../../services/api';
import MyLoader from '../../common/loader.component';
import {
  CleanConversation,
  CleanPurchaseOrder,
} from '../../../export-types/cleaned-types';
import { RouterProps } from '../../unified-app-router';
import { OutletContextParams } from './admin-purchase-orders.component';
import { usePaginationQs } from '../../../utils/usePagination';
import { ModalsService } from '../../../services/modals.service';
import { CompositeDeliveryCreateModal } from '../composite-delivery/composite-delivery-create-modal';
import { useConversationNewMessages } from '../../../api/conversation';
import { ConfirmModal } from '../../common/confirm-modal';
import { SelectedOrdersContext } from './admin-purchases-screen.component';
import { usePurchaseOrders } from '../../../api/use-purchase-orders';
import { useSearchParamsPaginationEffect } from './useSearchParamsPaginationEffect';
import { FormattedMessage } from 'react-intl';

type Props = {
  type: string;
  render: (props: RenderProps) => ReactElement;
} & RouterProps;
export type RenderProps = {
  type: string;
  data: CleanPurchaseOrder;
  onClick: (rowData: CleanPurchaseOrder) => void;
  checked: boolean;
  onChecked: (value: boolean) => void;
} & RouterProps;

export const EmployeePurchaseOrdersTab: React.FC<Props> = ({
  type,
  render,
  ...routerProps
}) => {
  const navigate = useNavigate();
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(15);
  useSearchParamsPaginationEffect(handleSetPage);

  const { debouncedInternalId, direction, linked, selectSupplierId, overdue } =
    useOutletContext<OutletContextParams>();

  const [orders, setOrders] = React.useState<string[]>([]);

  let sort: string;
  let status: Array<string> = [];
  switch (type) {
    case 'waitingForPayment':
      sort = 'createdAt';
      status = ['waitingForPayment'];
      break;
    case 'inWork':
      sort = 'paidAt';
      status = ['inWork'];
      break;
    case 'frozen':
      sort = 'paidAt';
      status = ['frozen'];
      break;
    case 'onTheWayToStock':
      sort = 'purchaseDate';
      status = ['onTheWayToStock'];
      break;
    case 'receivedInStock':
      sort = 'receiptDate';
      status = ['receivedInStock'];
      break;
    case 'receivedInRetailStock':
      sort = 'receiptDate';
      status = ['receivedInRetailStock'];
      break;
    case 'archive':
      sort = 'createdAt';
      status = ['sentToRussia', 'canceled'];
      break;
    default:
      sort = 'createdAt';
      break;
  }

  const { data, error, isLoading, mutate } = usePurchaseOrders({
    sort,
    direction,
    linked,
    overdue,
    status: status.length > 0 ? status : undefined,
    take,
    skip,
    internalId: debouncedInternalId || undefined,
    supplierId: selectSupplierId || undefined,
  });

  const newMessageHandler = React.useCallback(
    (updatedConversation: CleanConversation) => {
      if (
        data?.items.some(
          (order) => order.id === updatedConversation.purchaseOrder?.id,
        )
      ) {
        mutate();
      }
    },
    [data, mutate],
  );
  useConversationNewMessages(newMessageHandler, 'purchases');

  const { isSelectAll, isUnselectAll, setSelectedOrders } = useContext(
    SelectedOrdersContext,
  );

  useEffect(() => {
    if (data) {
      const orders = new Set(data?.items.map((item) => item.id));
      setSelectedOrders(orders);
    }
  }, [data, isSelectAll, setSelectedOrders]);

  useEffect(() => {
    if (data) {
      setSelectedOrders(new Set());
    }
  }, [data, isUnselectAll, setSelectedOrders]);

  const onRowClick = (rowData: CleanPurchaseOrder) => {
    navigate(`/purchases/${rowData.id}`);
  };

  const onChecked = (value: boolean, rowData: CleanPurchaseOrder) => {
    if (value) {
      setOrders([rowData.id, ...orders]);
    } else {
      const newValues = Array.from(orders);
      const index = newValues.indexOf(rowData.id);
      newValues.splice(index, 1);
      setOrders(newValues);
    }
  };

  const selectPage = () => {
    const newValues = Array.from(orders);
    const pageOrdersId = data?.items.map((order) => order.id) ?? [];
    newValues.push(...pageOrdersId);
    setOrders(Array.from(new Set(newValues)));
  };

  const createCompositeDelivery = () => {
    ModalsService.createModal(CompositeDeliveryCreateModal, {
      orders: orders,
    }).then(() => {
      mutate();
    });
  };

  const movePurchaseOrders = () => {
    ModalsService.createModal(ConfirmModal, {
      title: 'Отключить автоотправку для выбранных заказов?',
      buttonText: 'Отключить',
    }).then(() => {
      api
        .post(`/purchase-order/auto-send`, {
          purchaseOrders: orders,
        })
        .then(() => {
          mutate();
        });
    });
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <>
      {type === 'receivedInRetailStock' && (
        <div className="pb-4 d-flex flex-row">
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => createCompositeDelivery()}
          >
            <FormattedMessage
              defaultMessage="Создать транзитную посылку"
              id="purchaseOrders.btn.сreateATransitParcel"
              description="Кнопка"
            />
          </button>
          <button
            type="submit"
            className="btn btn-link"
            onClick={() => selectPage()}
          >
            <FormattedMessage
              defaultMessage="Выбрать все на странице"
              id="purchaseOrders.btn.SelectEverything"
              description="Кнопка"
            />
          </button>
          <button
            type="submit"
            className="btn btn-link"
            onClick={() => setOrders([])}
          >
            <FormattedMessage
              defaultMessage="Снять отметки"
              id="purchaseOrders.btn.Uncheck"
              description="Кнопка"
            />
          </button>
          <button
            type="submit"
            className="btn btn-success"
            onClick={() => movePurchaseOrders()}
          >
            <FormattedMessage
              defaultMessage="Отключить автоотправку"
              id="purchaseOrders.btn.DisableAutoSend"
              description="Кнопка"
            />
          </button>
        </div>
      )}
      <div className="pb-4">
        {data.items.map((data) =>
          render({
            data,
            type,
            onClick: onRowClick,
            checked: orders.includes(data.id),
            onChecked: (val) => onChecked(val, data),
            ...routerProps,
          }),
        )}
      </div>
      {data.total > take && (
        <div className="pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={data?.total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
