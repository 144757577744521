import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  FinanceAdminScreen,
  RbacType,
} from './screens/finance/finance-admin-screen.component';
import { AdminCreatePurchaseOrderScreen } from './screens/purchase-order/purchase-order-create-screen';
import { AdminOrdersScreen } from './screens/purchase-order/admin-purchases-screen.component';
import { AdminPurchaseOrderViewScreen } from './screens/purchase-order/admin-purchase-order-page.component';
import { DeliveryMethodScreen } from './screens/delivery/delivery-method-screen.component';
import { DeliveryOrderScreen } from './screens/delivery/delivery-order-screen';
import { PackageMethodScreen } from './screens/delivery/package-method-screen.component';
import { EmployeePurchaseOrdersTab } from './screens/purchase-order/employee-purchase-orders-tab';
import { PurchaseOrderDeliveryTariffsTab } from './screens/purchase-order/purchase-order-delivery-tariffs-tab.component';
import { AdminPurchaseOrdersTabs } from './screens/purchase-order/admin-purchase-orders.component';
import { AdminDeliveryScreen } from './screens/delivery/admin-deliveries-screen.component';
import { AdminDeliveryOrdersScreen } from './screens/delivery/admin-delivery-orders-screen';
import { DeliveryMarkupsTab } from './screens/delivery/delivery-markups-tab.component';
import { PackageMethodsTab } from './screens/delivery/package-methods-tab.component';
import { DeliveryMethodsTab } from './screens/delivery/delivery-methods-tab.component';
import { DeliveryInsuranceTab } from './screens/delivery/delivery-insurance-tab.components';
import { AdminParcelCreateScreen } from './screens/parcels/admin-parcel-create-screen';
import { EmployeeParcelsTable } from './screens/parcels/employee-parcels-table';
import {
  AdminParcelItem,
  ParcelStatusProps,
} from './screens/parcels/parcel-item';
import { AdminParcelPage } from './screens/parcels/admin-parcel-page';
import { AdminMarketingScreen } from './screens/marketing/admin-marketing-screen';
import { MarketingStoriesTab } from './screens/marketing/marketing-stories-tab';
import { ClientExpansesBasedCommissionsCreateScreen } from './screens/purchase-order/client-expanses-based-commissions-create-screen';
import { ClientExpansesBasedCommissionsTableScreen } from './screens/purchase-order/client-expanses-based-commissions-table-screen';
import { ClientExpansesBasedCommissionsEditScreen } from './screens/purchase-order/client-expanses-based-commissions-edit-screen';
import * as Sentry from '@sentry/react';
import { EmployeeParcelsScreen } from './screens/parcels/employee-parcels-screen';
import { DeliveryOrdersTab } from './screens/delivery/delivery-orders-tab';
import { AuditLogScreen } from './screens/audit-log/audit-log-screen';
import { AdminDeliveryOrderItem } from './screens/delivery/admin-delivery-order-item';
import { AdminPurchaseOrderItem } from './screens/purchase-order/admin-purchase-order-item';
import { CleanProfile, RbacObject } from '../export-types/cleaned-types';
import { Rbac } from './common/Rbac';
import { RbacSome } from './common/Rbac';
import { AccessDenied } from './common/AccessDenied';
import { TransactionsHistoryTable } from './screens/finance/transactions-history-table.component';
import { TopUpAccounts } from './screens/finance/top-up-accounts';
import { LocalDeliveryMethodsTab } from './screens/delivery/local-delivery-methods-tab.component';
import { LocalDeliveryMethodScreen } from './screens/delivery/local-delivery-method-screen.component';
import { SuppliersLayout } from './screens/suppliers/suppliers-layout.component';
import { SuppliersTable } from './screens/suppliers/suppliers-table.component';
import { LocationsTable } from './screens/suppliers/locations-table.component';
import { UsersClientsTable } from './screens/users/users-clients-tab.component';
import { UsersEmployeesTable } from './screens/users/users-employees-tab.component';
import { UsersScreen } from './screens/users/users-screen.component';
import { AutoSendParametersScreen } from './screens/purchase-order/auto-send-parameters-screen';
import {
  CompositeDeliveryPropsStatus,
  CompositeDeliveriesScreen,
} from './screens/composite-delivery/composite-deliveries-screen';
import { CompositeDeliveriesTab } from './screens/composite-delivery/composite-delivery-tab';
import { AdminCompositeDeliveryItem } from './screens/composite-delivery/composite-delivery-item';
import { CompositeDeliveryScreen } from './screens/composite-delivery/composite-delivery-screen';
import {
  ConversationItem,
  ConversationStatus,
} from './screens/conversations/conversation-item';
import { ConversationScreen } from './screens/conversations/conversations-screen';
import { ConversationList } from './screens/conversations/conversation-list';
import { PromotionsTab } from './screens/marketing/promotions-tab';
import { DeliveryInsuranceScreen } from './screens/delivery/delivery-insurance-screen.components';
import { OtcRecommendationTab } from './screens/marketing/OtcRecommendationTab';
import { ServiceChargeOptionScreen } from './screens/purchase-order/service-charge-option-screen';
import { ServiceChargeOptionForm } from './screens/purchase-order/service-charge-option-form';
import { RefillTransactionsTable } from './screens/finance/refill-transactions-table.component';
import { WriteOffsTable } from './screens/finance/writeoffs-table.component';
import { CurrencyTable } from './screens/finance/currency-table.component';
import { DeliveryOrderCreateScreen } from './screens/delivery/delivery-order-create-screen.component';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export type RouterProps = {
  user: CleanProfile;
  isAdmin: boolean;
  isPurchase: boolean;
  isWarehouse: boolean;
};

type PurchaseRoute<Object extends RbacObject | undefined = undefined> = {
  path: string;
  status: string;
  rbac?: Object extends RbacObject ? RbacType<Object> : undefined;
};

const WFP: PurchaseRoute<RbacObject.Purchase> = {
  path: 'waiting-for-payment',
  status: 'waitingForPayment',
  rbac: { object: RbacObject.Purchase, action: 'read:extended-statuses' },
};

const purchaseRoutes = [
  WFP,
  { path: 'in-work', status: 'inWork' },
  { path: 'frozen', status: 'frozen' },
  { path: 'on-the-way', status: 'onTheWayToStock' },
  { path: 'received-in-stock', status: 'receivedInStock' },
  { path: 'received-in-retail-stock', status: 'receivedInRetailStock' },
  { path: 'archive', status: 'archive' },
];

type ParcelRoute = {
  path: string;
  type: 'onTheWayToStock' | 'receivedInStock' | 'archive';
  status: ReadonlyArray<ParcelStatusProps>;
};
const parcelRoutes: ReadonlyArray<ParcelRoute> = [
  { path: 'on-the-way', type: 'onTheWayToStock', status: ['onTheWayToStock'] },
  {
    path: 'received-in-stock',
    type: 'receivedInStock',
    status: ['receivedInStock'],
  },
  { path: 'archive', type: 'archive', status: ['sentToRussia', 'canceled'] },
];

type ConversationRoute = {
  path: string;
  status: ConversationStatus | null;
};
const conversationRoutes: ReadonlyArray<ConversationRoute> = [
  { path: 'all', status: null },
  {
    path: 'refund',
    status: 'refund',
  },
  { path: 'unread', status: 'unread' },
  { path: 'defectCheck', status: 'defectCheck' },
  { path: 'delayed', status: 'delayed' },
  { path: 'adminAssistance', status: 'adminAssistance' },
  { path: 'archive', status: 'archive' },
];

type DeliveryRoute = {
  path: string;
  status: string;
};
const deliveryRoutes: ReadonlyArray<DeliveryRoute> = [
  { path: 'processing', status: 'processing' },
  { path: 'assembly', status: 'assembly' },
  { path: 'sent-to-russia', status: 'sentToRussia' },
  { path: 'received-in-russia', status: 'receivedInRussia' },
  { path: 'received-in-retail-russia', status: 'receivedInRetailRussia' },
  { path: 'sent-to-recipient', status: 'sentToRecipient' },
  { path: 'delivered', status: 'delivered' },
];

type CompositeDeliveryRoute = {
  path: string;
  status: CompositeDeliveryPropsStatus;
};
const compositeDeliveryRoutes: ReadonlyArray<CompositeDeliveryRoute> = [
  { path: 'assembly', status: 'assembly' },
  {
    path: 'sent-to-russia',
    status: 'sentToRussia',
  },
  { path: 'received-in-russia', status: 'receivedInRussia' },
];

export const UnifiedAppRouter = (props: RouterProps) => {
  return (
    <SentryRoutes>
      <Route
        path="/users"
        element={
          <Rbac
            object={RbacObject.User}
            action={'read:admin'}
            otherwise={() => <AccessDenied />}
          >
            <UsersScreen />
          </Rbac>
        }
      >
        <Route index element={<Navigate to="/users/clients" replace />} />
        <Route path="/users/clients" element={<UsersClientsTable />} />
        <Route path="/users/employees" element={<UsersEmployeesTable />} />
      </Route>
      <Route
        path="/finances-admin"
        element={
          <Rbac
            object={RbacObject.Transaction}
            action={['read:admin', 'read:supplier']}
            otherwise={() => <AccessDenied />}
          >
            <FinanceAdminScreen />
          </Rbac>
        }
      >
        <Route
          index
          element={<Navigate to="/finances-admin/history" replace />}
        />
        <Route
          path="/finances-admin/history"
          element={<TransactionsHistoryTable />}
        />
        <Route
          index
          element={<Navigate to="/finances-admin/refills" replace />}
        />
        <Route
          path="/finances-admin/refills"
          element={<RefillTransactionsTable />}
        />
        <Route path="/finances-admin/write-offs" element={<WriteOffsTable />} />
        <Route
          path="/finances-admin/currency"
          element={
            <Rbac
              object={RbacObject.Currency}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <CurrencyTable />
            </Rbac>
          }
        />
        <Route
          path="/finances-admin/top-up-accounts"
          element={
            <Rbac
              object={RbacObject.TopUpMethod}
              action={'read:accounts'}
              otherwise={() => <AccessDenied />}
            >
              <TopUpAccounts />
            </Rbac>
          }
        />
      </Route>
      <Route
        path="/audit-log"
        element={
          <Rbac
            object={RbacObject.AuditLog}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <AuditLogScreen />
          </Rbac>
        }
      />
      <Route
        path="/deliveries/delivery-method"
        element={
          <Rbac
            object={RbacObject.DeliveryMethod}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <DeliveryMethodScreen />
          </Rbac>
        }
      />
      <Route
        path="/deliveries/local-delivery-method"
        element={
          <Rbac
            object={RbacObject.LocalDeliveryMethod}
            action={'write'}
            otherwise={() => <AccessDenied />}
          >
            <LocalDeliveryMethodScreen />
          </Rbac>
        }
      />
      <Route
        path="/deliveries/package-method"
        element={
          <Rbac
            object={RbacObject.PackageMethod}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <PackageMethodScreen />
          </Rbac>
        }
      />
      <Route
        path="/deliveries/delivery-insurance"
        element={
          <Rbac
            object={RbacObject.DeliveryInsurance}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <DeliveryInsuranceScreen />
          </Rbac>
        }
      />
      <Route
        path="/deliveries/delivery-order-create"
        element={
          <Rbac
            object={RbacObject.DeliveryOrder}
            action={'write'}
            otherwise={() => <AccessDenied />}
          >
            <DeliveryOrderCreateScreen />
          </Rbac>
        }
      />
      <Route
        path="/marketing"
        element={
          <Rbac
            object={RbacObject.Story}
            action={'read:admin'}
            otherwise={() => <AccessDenied />}
          >
            <AdminMarketingScreen />
          </Rbac>
        }
      >
        <Route index element={<Navigate to="stories" replace />} />
        <Route path="stories" element={<MarketingStoriesTab />} />
        <Route path="promotions" element={<PromotionsTab />} />
        <Route path="otcRecommendation" element={<OtcRecommendationTab />} />
      </Route>
      <Route
        path="/purchases/commissions/create"
        element={
          <Rbac
            object={RbacObject.PurchaseOrderClientExpensesPurchaseMarkup}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <ClientExpansesBasedCommissionsCreateScreen />
          </Rbac>
        }
      />
      <Route
        path="/purchases/commissions/:id"
        element={
          <Rbac
            object={RbacObject.PurchaseOrderClientExpensesPurchaseMarkup}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <ClientExpansesBasedCommissionsEditScreen />
          </Rbac>
        }
      />
      <Route
        path="/purchases/form"
        element={
          <Rbac
            object={RbacObject.Purchase}
            action={'write:admin-create'}
            otherwise={() => <AccessDenied />}
          >
            <AdminCreatePurchaseOrderScreen />
          </Rbac>
        }
      />
      <Route
        path="/purchases/parcel-form"
        element={
          <Rbac
            object={RbacObject.Parcel}
            action={'write:admin-create'}
            otherwise={() => <AccessDenied />}
          >
            <AdminParcelCreateScreen />
          </Rbac>
        }
      />

      <Route
        path="/deliveries/delivery-order/:id"
        element={
          <Rbac
            object={RbacObject.DeliveryOrder}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <DeliveryOrderScreen />
          </Rbac>
        }
      />
      <Route
        path="/composite-deliveries/:id"
        element={
          <Rbac
            object={RbacObject.CompositeDeliveryOrder}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <CompositeDeliveryScreen />
          </Rbac>
        }
      />
      <Route
        path="/composite-deliveries"
        element={
          <Rbac
            object={RbacObject.CompositeDeliveryOrder}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <CompositeDeliveriesScreen />
          </Rbac>
        }
      >
        <Route
          index
          element={<Navigate to="/composite-deliveries/assembly" replace />}
        />
        {compositeDeliveryRoutes.map(({ path, status }) => (
          <Route
            path={path}
            key={path}
            element={
              <CompositeDeliveriesTab
                render={(props) => (
                  <AdminCompositeDeliveryItem key={props.data.id} {...props} />
                )}
                key={path}
                status={status}
                {...props}
              />
            }
          />
        ))}
      </Route>
      <Route
        path="/deliveries"
        element={
          <RbacSome
            checks={[
              { object: RbacObject.DeliveryOrder, action: 'read' },
              { object: RbacObject.DeliveryOrder, action: 'read:transit' },
            ]}
            otherwise={() => <AccessDenied />}
          >
            <AdminDeliveryScreen {...props} />
          </RbacSome>
        }
      >
        <Route index element={<Navigate to="/deliveries/orders" replace />} />
        <Route
          path="/deliveries/orders"
          element={<AdminDeliveryOrdersScreen />}
        >
          <Route
            index
            element={<Navigate to="/deliveries/orders/assembly" replace />}
          />
          {deliveryRoutes.map(({ path, status }) => (
            <Route
              path={path}
              key={path}
              element={
                <DeliveryOrdersTab
                  status={status}
                  render={(props) => (
                    <AdminDeliveryOrderItem key={props.data.id} {...props} />
                  )}
                  {...props}
                />
              }
            />
          ))}
        </Route>

        <Route
          path="/deliveries/delivery"
          element={
            <Rbac
              object={RbacObject.DeliveryMethod}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <DeliveryMethodsTab />
            </Rbac>
          }
        />
        <Route
          path="/deliveries/package"
          element={
            <Rbac
              object={RbacObject.PackageMethod}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <PackageMethodsTab />
            </Rbac>
          }
        />
        <Route
          path="/deliveries/markups"
          element={
            <Rbac
              object={RbacObject.DeliveryOrderDeliveryMarkup}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <DeliveryMarkupsTab />
            </Rbac>
          }
        />
        <Route
          path="/deliveries/local-delivery"
          element={
            <Rbac
              object={RbacObject.LocalDeliveryMethod}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <LocalDeliveryMethodsTab />
            </Rbac>
          }
        />
        <Route
          path="/deliveries/insurance"
          element={
            <Rbac
              object={RbacObject.DeliveryInsurance}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <DeliveryInsuranceTab />
            </Rbac>
          }
        />
      </Route>

      <Route
        path="/parcels/:id"
        element={
          <Rbac
            object={RbacObject.Parcel}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <AdminParcelPage />
          </Rbac>
        }
      />
      <Route
        path="/parcels"
        element={
          <Rbac
            object={RbacObject.Parcel}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <EmployeeParcelsScreen />
          </Rbac>
        }
      >
        <Route index element={<Navigate to="/parcels/on-the-way" replace />} />
        {parcelRoutes.map(({ path, type, status }) => (
          <Route
            path={path}
            key={path}
            element={
              <EmployeeParcelsTable
                render={(props) => (
                  <AdminParcelItem key={props.data.id} {...props} />
                )}
                key={path}
                type={type}
                status={status}
              />
            }
          />
        ))}
      </Route>
      {/*костыль для сотрудников: после рефакторинга поменялись роуты */}
      {purchaseRoutes.map(({ path }) => (
        <Route
          key={path}
          path={`/purchases/${path}`}
          element={<Navigate to={`/purchases/orders/${path}`} replace />}
        />
      ))}
      <Route
        path="/purchases/:id"
        element={
          <Rbac
            object={RbacObject.Purchase}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <AdminPurchaseOrderViewScreen />
          </Rbac>
        }
      />
      <Route
        path="/purchases"
        element={
          <Rbac
            object={RbacObject.Purchase}
            action={'read'}
            otherwise={() => <AccessDenied />}
          >
            <AdminOrdersScreen />
          </Rbac>
        }
      >
        <Route index element={<Navigate to="/purchases/orders" replace />} />
        <Route path="/purchases/orders" element={<AdminPurchaseOrdersTabs />}>
          <Route
            index
            element={
              <Rbac
                object={RbacObject.Purchase}
                action={'read:extended-statuses'}
                otherwise={() => (
                  <Navigate to="/purchases/orders/in-work" replace />
                )}
              >
                <Navigate to="/purchases/orders/waiting-for-payment" replace />
              </Rbac>
            }
          />
          {purchaseRoutes.map(({ path, status, rbac }) => {
            const element = (
              <EmployeePurchaseOrdersTab
                key={status}
                type={status}
                render={(props) => (
                  <AdminPurchaseOrderItem key={props.data.id} {...props} />
                )}
                {...props}
              />
            );

            return (
              <Route
                path={path}
                key={path}
                element={
                  rbac ? (
                    <Rbac
                      key={path}
                      object={rbac.object}
                      action={rbac.action}
                      otherwise={() => <AccessDenied />}
                    >
                      {element}
                    </Rbac>
                  ) : (
                    element
                  )
                }
              />
            );
          })}
        </Route>

        <Route
          path="/purchases/tariffs"
          key={'tariffs'}
          element={
            <Rbac
              object={RbacObject.PurchaseOrderDeliveryTariff}
              action={'write'}
              otherwise={() => <AccessDenied />}
            >
              <PurchaseOrderDeliveryTariffsTab />
            </Rbac>
          }
        />
        <Route
          path="/purchases/commissions"
          element={
            <Rbac
              object={RbacObject.PurchaseOrderClientExpensesPurchaseMarkup}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <ClientExpansesBasedCommissionsTableScreen />
            </Rbac>
          }
        />
        <Route
          path="/purchases/auto-send"
          element={
            <Rbac
              object={RbacObject.AutoSendParameters}
              action={'read'}
              otherwise={() => <AccessDenied />}
            >
              <AutoSendParametersScreen />
            </Rbac>
          }
        />
        <Route
          path="/purchases/service-charge-option"
          element={
            <Rbac
              object={RbacObject.ServiceChargeOption}
              action={'read:admin'}
              otherwise={() => <AccessDenied />}
            >
              <ServiceChargeOptionScreen />
            </Rbac>
          }
        />
      </Route>
      <Route
        path="/purchases/service-charge-option/form"
        element={
          <Rbac
            object={RbacObject.ServiceChargeOption}
            action={'write:admin'}
            otherwise={() => <AccessDenied />}
          >
            <ServiceChargeOptionForm />
          </Rbac>
        }
      />
      <Route
        path="/suppliers"
        element={
          <Rbac
            object={RbacObject.Supplier}
            action={'read:admin'}
            otherwise={() => <AccessDenied />}
          >
            <SuppliersLayout />
          </Rbac>
        }
      >
        <Route index element={<Navigate to="list" replace />} />
        <Route path="list" element={<SuppliersTable />} />
        <Route path="location" element={<LocationsTable />} />
      </Route>

      <Route
        path="*"
        element={
          <Rbac
            object={RbacObject.Purchase}
            action={'read'}
            otherwise={() => <Navigate to={'/deliveries'} replace />}
          >
            <Navigate to={'/purchases'} replace />
          </Rbac>
        }
      />
      <Route path="/conversations" element={<ConversationScreen />}>
        <Route index element={<Navigate to="/conversations/all" replace />} />
        {conversationRoutes.map(({ path, status }) => (
          <Route
            path={path}
            key={path}
            element={
              <ConversationList
                render={(props) => (
                  <ConversationItem key={props.data.id} {...props} />
                )}
                key={path}
                status={status}
              />
            }
          />
        ))}
      </Route>
    </SentryRoutes>
  );
};
