import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { required } from '../../../utils/validations';
import { InputWrapper } from '../../common/input-wrapper';
import { fetcher } from '../../../services/api';
import { AlertService } from '../../../services/alert.service';
import { useModalClose } from '../../../utils/useModalClose';
import { trpcSwr } from '../../../api/trpc';
import { Item } from './OtcRecommendationTab';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import { uploadFile } from '../../../services/file-service';

type Form = {
  disabled: boolean;
  objectId: string;
  image: MediaInputValue[] | undefined;
  name: string;
  tags: string;
  price: number | undefined;
  approve: number | undefined;
  ctr: number | undefined;
  boost: number | undefined;
  stock: string;
};

type ModalProps = {
  close: () => void;
  reject: () => void;
  item?: Item;
  hasEnoughBoost?: boolean;
};

export const AddOtcRecommendation: React.FC<ModalProps> = ({
  close,
  reject,
  item,
  hasEnoughBoost,
}) => {
  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
  } = useForm<Form>({
    defaultValues: item
      ? {
          objectId: item.objectId,
          image: item.image
            ? [
                {
                  type: 'exist' as const,
                  file: item.image,
                },
              ]
            : undefined,
          name: item.name || '',
          tags: item.tags || '',
          price: item.price || undefined,
          stock: item.stock || '',
          approve: item.approve || undefined,
          ctr: Number((item.ctr * 100).toFixed(6)) || undefined,
          boost: item.boost || undefined,
        }
      : {},
  });

  const { trigger: triggerUpdate } =
    trpcSwr.otcRecommendationAdmin.update.useSWRMutation({ fetcher });
  const { trigger: triggerCreate } =
    trpcSwr.otcRecommendationAdmin.create.useSWRMutation({ fetcher });

  const onSubmit = handleSubmit(async (form) => {
    let imageId: string | null;
    if (form.image?.length) {
      if (form.image[0].type === 'new') {
        const uploadedFile = await uploadFile(form.image[0].file);
        imageId = uploadedFile.file.id;
      } else {
        imageId = form.image[0].file.id;
      }
    } else {
      imageId = null;
    }

    const data = {
      objectId: form.objectId,
      imageId,
      name: form.name ? form.name : null,
      tags: form.tags ? form.tags : null,
      price: form.price ? Number(form.price) : null,
      approve: form.approve ? Number(form.approve) : null,
      boost: form.boost ? Number(form.boost) : null,
      stock: form.stock ? form.stock : null,
      ctrResetAt: form.ctr ? null : new Date(),
    };
    if (item) {
      await triggerUpdate({
        id: item.id,
        data: data,
      });
    } else {
      await triggerCreate(data);
    }
    AlertService.success();
    close();
  });

  const modalRef = useModalClose(reject);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>{item ? 'Редактировать' : 'Добавить'} рекомендацию</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => reject()}
            disabled={isSubmitting}
          />
        </div>
        <div className="modal-body p-4">
          <Controller
            control={control}
            name="objectId"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Артикул"
                error={fieldState.error?.message}
                required
              >
                <input className="form-control mb-3" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="image"
            render={({ field, fieldState }) => (
              <InputWrapper
                theme="light"
                className="mt-3"
                title="Фото товара"
                error={fieldState.error?.message}
              >
                <MediaInput {...field} className="form-control mb-3" />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Название товара"
                error={fieldState.error?.message}
              >
                <input className="form-control mb-3" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="tags"
            render={({ field, fieldState }) => (
              <InputWrapper title="Категория" error={fieldState.error?.message}>
                <input className="form-control mb-3" type="text" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="price"
            render={({ field, fieldState }) => (
              <InputWrapper
                title="Сумма выплаты"
                error={fieldState.error?.message}
              >
                <input className="form-control mb-3" type="number" {...field} />
              </InputWrapper>
            )}
          />
          <Controller
            control={control}
            name="approve"
            render={({ field, fieldState }) => (
              <InputWrapper title="%Выкупа" error={fieldState.error?.message}>
                <input className="form-control mb-3" type="number" {...field} />
              </InputWrapper>
            )}
          />
          {item ? (
            <>
              <Controller
                control={control}
                name="ctr"
                render={({ field, fieldState }) => (
                  <InputWrapper title="CTR%" error={fieldState.error?.message}>
                    <div className="d-flex gap-2">
                      <input
                        className="form-control mb-3"
                        readOnly
                        {...field}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-success mb-3 justify-content-end"
                        disabled={isSubmitting}
                        onClick={() => field.onChange('')}
                      >
                        Сбросить
                      </button>
                    </div>
                  </InputWrapper>
                )}
              />
              {hasEnoughBoost || (!hasEnoughBoost && item.boost) ? (
                <Controller
                  control={control}
                  name="boost"
                  render={({ field, fieldState }) => (
                    <InputWrapper
                      title="Разгон"
                      error={fieldState.error?.message}
                    >
                      <input
                        className="form-control mb-3"
                        type="number"
                        {...field}
                      />
                    </InputWrapper>
                  )}
                />
              ) : null}
            </>
          ) : null}

          <Controller
            control={control}
            name="stock"
            render={({ field, fieldState }) => (
              <InputWrapper title="Склад" error={fieldState.error?.message}>
                <input className="form-control" type="text" {...field} />
              </InputWrapper>
            )}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : item ? (
              'Сохранить'
            ) : (
              'Добавить'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
