import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Pagination from 'rsuite/Pagination';
import MyLoader from '../../common/loader.component';
import {
  CleanConversation,
  CleanDeliveryOrder,
} from '../../../export-types/cleaned-types';
import { RouterProps } from '../../unified-app-router';
import { OutletContextParams } from './admin-delivery-orders-screen';
import { usePaginationQs } from '../../../utils/usePagination';
import { useConversationNewMessages } from '../../../api/conversation';
import { useDeliveryOrders } from '../../../api/use-delivery-orders';
import { useSearchParamsPaginationEffect } from '../purchase-order/useSearchParamsPaginationEffect';

export type RenderProps = {
  onClick: (rowData: { id: string }) => void;
  onPurchaseRowClick: (rowData: { type: string; id: string }) => void;
  onHideClick: (rowData: { id: string }) => void;
  status: string;
  collapsed: boolean;
  data: CleanDeliveryOrder;
} & RouterProps;

type Props = {
  status: string;
  render: (props: RenderProps) => JSX.Element;
} & RouterProps;

export const DeliveryOrdersTab = ({
  status,
  render,
  ...routerProps
}: Props) => {
  const navigate = useNavigate();

  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(15);

  useSearchParamsPaginationEffect(handleSetPage);
  const {
    debouncedInternalId,
    linked,
    expanded,
    collapsed,
    onChangeCollapsed,
    selectSupplier,
  } = useOutletContext<OutletContextParams>();

  const { data, error, isLoading, mutate } = useDeliveryOrders({
    linked,
    status: status ? [status] : undefined,
    take,
    skip,
    internalId: debouncedInternalId || undefined,
    supplierId: selectSupplier || undefined,
  });

  const newMessageHandler = React.useCallback(
    (updatedConversation: CleanConversation) => {
      if (
        data?.items.some(
          (order) => order.id === updatedConversation.purchaseOrder?.id,
        )
      ) {
        mutate();
      }
    },
    [data, mutate],
  );
  useConversationNewMessages(newMessageHandler, 'deliveries');

  const onRowClick = (rowData: { id: string }) => {
    navigate(`/deliveries/delivery-order/${rowData.id}`);
  };

  const onHideClick = (rowData: { id: string }) => {
    if (collapsed.includes(rowData.id)) {
      const newCollapsed = [...collapsed];
      newCollapsed.splice(newCollapsed.indexOf(rowData.id), 1);
      onChangeCollapsed(newCollapsed);
    } else {
      onChangeCollapsed([...collapsed, rowData.id]);
    }
  };

  const onPurchaseRowClick = (rowData: { type: string; id: string }) => {
    navigate(
      rowData.type === 'purchase'
        ? `/purchases/${rowData.id}`
        : `/parcels/${rowData.id}`,
    );
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <>
      <div className="pb-4">
        {data.items.map((data) =>
          render({
            data,
            onPurchaseRowClick,
            onClick: onRowClick,
            onHideClick,
            collapsed:
              (!expanded && collapsed.includes(data.id)) ||
              (expanded && !collapsed.includes(data.id)),
            status,
            ...routerProps,
          }),
        )}
      </div>
      {data.total > take && (
        <div className="pb-4">
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="md"
            layout={['pager']}
            total={data?.total}
            limitOptions={[15, 20]}
            limit={take}
            activePage={page}
            onChangePage={handleSetPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      )}
    </>
  );
};
