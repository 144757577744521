import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { User, UserSelect } from '../../common/user-select';
import { InputWrapper } from '../../common/input-wrapper';
import {
  decimalValidation,
  decimalValidationCurrency,
  required,
} from '../../../utils/validations';
import { ModalProps } from '../../../services/modals.service';
import { useModalClose } from '../../../utils/useModalClose';
import {
  DECIMAL_PLACES,
  DECIMAL_STEP,
  getCurrencySymbol,
  toMoney,
} from '../../../utils/common.utils';
import { api } from '../../../services/api';
import { useGlobalMutate } from '../../../api/useGlobalMutate';
import { ActualCurrency } from './ActualCurrency';
import { useCurrencyCurrent } from '../../../services/currency.service';
import { useUsers } from '../../../api/users';

type ConvertCurrency = 'usd' | 'cny';

type Form = {
  clientId: User;
  currencyFrom: ConvertCurrency;
  currencyTo: ConvertCurrency;
  withdrawAmount: string;
  rate: string;
  percentRate: string;
};

const defaultPercentRate = 0.5;
export const CurrencyConversionModal = ({ close }: ModalProps) => {
  const { data: currencies } = useCurrencyCurrent();
  const [selectedCurrencyFrom, setSelectedCurrencyFrom] = useState('');
  const [selectedCurrencyTo, setSelectedCurrencyTo] = useState('');
  const [userId, setUserId] = useState<number | null>();

  const { data: selectedUser } = useUsers(['client'], userId?.toString());

  const { mutate } = useGlobalMutate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<Form>();

  const [withdrawAmount, rate, percentRate] = watch([
    'withdrawAmount',
    'rate',
    'percentRate',
  ]);

  const handleCurrencyFromChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedCurrencyFrom(selectedValue);
    setValue('currencyFrom', event.target.value as ConvertCurrency);
    setValue('rate', '');

    if (selectedValue === 'usd') {
      setSelectedCurrencyTo('cny');
      setValue('currencyTo', 'cny');
    } else if (selectedValue === 'cny') {
      setSelectedCurrencyTo('usd');
      setValue('currencyTo', 'usd');
    } else {
      setSelectedCurrencyTo('');
    }
  };

  const handleCurrencyToChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedCurrencyTo(selectedValue);
    setValue('currencyTo', event.target.value as ConvertCurrency);
    setValue('rate', '');

    if (selectedValue === 'usd') {
      setSelectedCurrencyFrom('cny');
      setValue('currencyFrom', 'cny');
    } else if (selectedValue === 'cny') {
      setSelectedCurrencyFrom('usd');
      setValue('currencyFrom', 'usd');
    } else {
      setSelectedCurrencyFrom('');
    }
  };

  const onSave = (form: Form) => {
    api
      .post('/balance/convert', {
        clientId: form.clientId.id,
        currencyFrom: form.currencyFrom,
        currencyTo: form.currencyTo,
        withdrawAmount: form.withdrawAmount,
        rate: form.rate,
      })
      .then(() => {
        mutate();
        close();
      });
  };

  useEffect(() => {
    setValue('percentRate', defaultPercentRate.toString());
  }, []);

  useEffect(() => {
    setValue('rate', '');
  }, [setValue, percentRate]);
  function calculateTargetAmount() {
    if (rate && rate !== '0' && withdrawAmount && withdrawAmount !== '0') {
      return toMoney((Number(withdrawAmount) * Number(rate)).toFixed(2));
    }
    return '';
  }

  const modalRef = useModalClose(close);

  function calculateRate(event: MouseEvent) {
    event.preventDefault();
    if (!selectedCurrencyFrom || !selectedCurrencyTo || !currencies) {
      return;
    }

    const newRate =
      selectedCurrencyFrom === 'usd'
        ? (Number(currencies.usdRubExternal) /
            Number(currencies.cnyRubExternal)) *
          (1 - Number(percentRate) / 100)
        : (Number(currencies.cnyRubExternal) /
            Number(currencies.usdRubExternal)) *
          (1 - Number(percentRate) / 100);
    setValue('rate', newRate.toFixed(DECIMAL_PLACES), { shouldDirty: true });
  }

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(onSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Конвертация валюты клиента</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            control={control}
            name="clientId"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Клиент"
                required
                error={fieldState.error?.message}
              >
                <UserSelect
                  placeholder="Клиент"
                  setUserId={setUserId}
                  {...field}
                />
              </InputWrapper>
            )}
          />
          <div>
            <p className="mb-2">Текущий баланс </p>
            <div className="mb-3 d-flex">
              <InputWrapper className="me-5 ">
                <div className="input-group">
                  <input
                    className="form-control"
                    value={`${getCurrencySymbol('cny')} ${userId && selectedUser && selectedUser.items.length > 0 ? selectedUser.items.find((user) => user.internalId === userId)?.moneyBill.cny : ''}`}
                    readOnly
                  ></input>
                </div>
              </InputWrapper>
              <InputWrapper>
                <div className="input-group">
                  <input
                    className="form-control"
                    value={`${getCurrencySymbol('usd')} ${userId && selectedUser && selectedUser.items.length > 0 ? selectedUser.items.find((user) => user.internalId === userId)?.moneyBill.usd : ''}`}
                    readOnly
                  ></input>
                </div>
              </InputWrapper>
            </div>
          </div>
          <div className="mb-3 d-flex">
            <InputWrapper
              className="me-5"
              title="Валюта из"
              error={errors.currencyFrom?.message}
              required
            >
              <select
                className="form-select pe-4"
                value={selectedCurrencyFrom}
                onChange={handleCurrencyFromChange}
              >
                <option value="" hidden>
                  -
                </option>
                <option value="usd">USD</option>
                <option value="cny">CNY</option>
              </select>
            </InputWrapper>
            <InputWrapper
              title="Валюта в"
              error={errors.currencyTo?.message}
              required
            >
              <select
                className="form-select"
                value={selectedCurrencyTo}
                onChange={handleCurrencyToChange}
              >
                <option value="" hidden>
                  -
                </option>
                <option value="usd">USD</option>
                <option value="cny">CNY</option>
              </select>
            </InputWrapper>
          </div>
          <InputWrapper
            title="Сумма списания"
            className="mb-3"
            error={errors.withdrawAmount?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('withdrawAmount', {
                  ...required,
                  ...decimalValidation,
                })}
              />
              {selectedCurrencyFrom ? (
                <span className="input-group-text ps-3 pe-3">
                  {getCurrencySymbol(selectedCurrencyFrom)}
                </span>
              ) : (
                <span className="input-group-text ps-4 pe-3"></span>
              )}
            </div>
          </InputWrapper>
          <InputWrapper
            title="Отнять % курса"
            className="mb-3"
            error={errors.percentRate?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.000001}
                {...register('percentRate', {
                  ...required,
                })}
              />
              <span className="input-group-text">%</span>
            </div>
          </InputWrapper>
          <InputWrapper
            title="Курс"
            className="mb-3"
            error={errors.rate?.message}
            required
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={DECIMAL_STEP}
                {...register('rate', {
                  ...decimalValidationCurrency,
                  ...required,
                })}
              />
              <button
                onClick={calculateRate}
                className="btn btn-outline-secondary"
                disabled={!selectedCurrencyFrom || !selectedCurrencyTo}
              >
                Рассчитать
              </button>
            </div>
            <p className="rs-form-help-text">
              Нажмите Рассчитать чтобы использовать внутренний курс
            </p>
          </InputWrapper>
          {/*<ActualCurrency withPartner={false} />*/}
          <InputWrapper title="Сумма зачисления" className="mb-3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={calculateTargetAmount()}
                disabled
              />
              <span className="input-group-text">
                {getCurrencySymbol(selectedCurrencyTo)}
              </span>
            </div>
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
