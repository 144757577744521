import React from 'react';
import { toMoney } from '../../../utils/common.utils';

type PriceCellProps = {
  prePrice?: string;
  price: string;
  currency: 'cny' | 'usd' | 'try';
};

export const PriceCell = ({ prePrice, price, currency }: PriceCellProps) => {
  if (prePrice) return <>`≈ ${toMoney(prePrice, currency)}`</>;

  return <>{`${toMoney(price, currency)}`}</>;
};
