import React, { useContext, useEffect } from 'react';
import { CleanConversation } from '../export-types/cleaned-types';
import useSWR from 'swr';
import { fetcher } from '../services/api';

export const NewMessagesContext = React.createContext<EventManager | null>(
  null,
);

type Listener = (conversation: CleanConversation) => void;
type EventManager = {
  addEventListener(name: string, listener: Listener): void;
  removeEventListener(name: string): void;
  invoke(conversation: CleanConversation): void;
};

const createEventManager = (): EventManager => {
  const listeners = new Map<string, Listener>();
  return {
    invoke(conversation: CleanConversation): void {
      for (let listener of Array.from(listeners.values())) {
        listener(conversation);
      }
    },
    addEventListener(name: string, listener: Listener): void {
      listeners.set(name, listener);
    },
    removeEventListener(name: string): void {
      listeners.delete(name);
    },
  };
};

type Props = { userId?: string };
export const NewMessagesProvider = ({
  userId,
  children,
}: React.PropsWithChildren<Props>) => {
  const manager = React.useMemo(
    () => (userId ? createEventManager() : null),
    [userId],
  );

  useEffect(() => {
    if (manager) {
      const sse = new EventSource(`/api/conversation/new-messages`, {
        withCredentials: true,
      });

      sse.onmessage = (e) => manager.invoke(JSON.parse(e.data));
      sse.onerror = () => {
        sse.close();
      };
      return () => {
        sse.close();
      };
    }
  }, [manager]);

  return React.createElement(
    NewMessagesContext.Provider,
    {
      value: manager,
    },
    children,
  );
};

export function useConversationNewMessages(
  onNewMessage: Listener,
  name: string,
) {
  const context = useContext(NewMessagesContext);
  useEffect(() => {
    if (context) {
      context.addEventListener(name, onNewMessage);
      return () => {
        context.removeEventListener(name);
      };
    }
  }, [onNewMessage, name, context]);
}

export function useUnreadMessages(auth: boolean) {
  const { data, isLoading, mutate } = useSWR<{ unread: number }>(
    auth ? '/conversation/unread' : null,
    fetcher,
  );

  return { data, isLoading, mutate };
}
