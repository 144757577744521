import useSWR from 'swr';
import { CleanDeliveryOrder } from '../export-types/cleaned-types';
import { fetcher } from '../services/api';

type Params = {
  linked?: boolean;
  status?: Array<string>;
  take?: number;
  skip?: number;
  internalId?: string;
  withoutComposite?: boolean;
  supplierId?: number;
};

export function useDeliveryOrders(params?: Params) {
  return useSWR<{
    total: number;
    items: Array<CleanDeliveryOrder>;
  }>(
    {
      url: '/delivery-order/v2',
      params,
    },
    fetcher,
  );
}
