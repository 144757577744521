import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import Pagination from 'rsuite/Pagination';
import MyLoader from '../../common/loader.component';
import { CleanCompositeDelivery } from '../../../export-types/cleaned-types';
import { RouterProps } from '../../unified-app-router';
import { OutletContextParams } from './composite-deliveries-screen';
import { usePaginationQs } from '../../../utils/usePagination';
import { useSearchParamsPaginationEffect } from '../purchase-order/useSearchParamsPaginationEffect';

export type RenderProps = {
  onClick: (rowData: { id: string }) => void;
  onDeliveryRowClick: (rowData: { id: string }) => void;
  status: string;
  data: CleanCompositeDelivery;
} & RouterProps;

type Props = {
  status: string;
  render: (props: RenderProps) => JSX.Element;
} & RouterProps;

export const CompositeDeliveriesTab = ({
  status,
  render,
  ...routerProps
}: Props) => {
  const navigate = useNavigate();
  const { take, skip, handleSetPage, handleChangeLimit, page } =
    usePaginationQs(15);
  useSearchParamsPaginationEffect(handleSetPage);

  const { debouncedInternalId, selectSupplierId } =
    useOutletContext<OutletContextParams>();

  const { data, error, isLoading } = useSWR<{
    total: number;
    items: Array<CleanCompositeDelivery>;
  }>(
    () => ({
      url: '/composite-delivery',
      params: {
        status: [status],
        ...(debouncedInternalId
          ? {
              internalId: debouncedInternalId,
              supplierId: selectSupplierId,
              skip: 0,
            }
          : { take, skip }),
      },
    }),
    fetcher,
  );

  const onRowClick = (rowData: { id: string }) => {
    navigate(`/composite-deliveries/${rowData.id}`);
  };

  const onDeliveryRowClick = (rowData: { id: string }) => {
    navigate(`/deliveries/delivery-order/${rowData.id}`);
  };

  if (isLoading) {
    return (
      <>
        <div className="pb-4">
          <MyLoader />
        </div>
      </>
    );
  }

  if (error || !data) {
    return (
      <>
        <div className="pb-4">Неизвестная ошибка</div>
      </>
    );
  }

  return (
    <>
      <div className="pb-4">
        {data.items.map((data) =>
          render({
            data,
            onDeliveryRowClick,
            onClick: onRowClick,
            status,
            ...routerProps,
          }),
        )}
      </div>
      {data.total > take && (
        <Pagination
          className="pb-4"
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="md"
          layout={['pager']}
          total={data.total}
          limit={take}
          activePage={page}
          onChangePage={handleSetPage}
          onChangeLimit={handleChangeLimit}
        />
      )}
    </>
  );
};
