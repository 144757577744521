import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { decimalValidation, required } from '../../../utils/validations';
import useSWR from 'swr';
import { api, fetcher } from '../../../services/api';
import {
  getCurrencySymbol,
  getTopUpMethodTitle,
  toMoney,
} from '../../../utils/common.utils';
import { UserSelect, User } from '../../common/user-select';
import { InputWrapper } from '../../common/input-wrapper';
import ReactSelect from 'react-select';
import {
  AlipayAccount,
  CardToCardAccount,
  WeChatAdminAccount,
} from './top-up-dto';
import { AlertService } from '../../../services/alert.service';
import isDecimal from 'validator/es/lib/isDecimal';
import { useCurrencyCurrent } from '../../../services/currency.service';
import { useModalClose } from '../../../utils/useModalClose';

type TopUpModalProps = {
  close: () => void;
};

export type CardToCardMethod = {
  id: string;
  name: 'sberbank' | 'alfabank' | 'tinkoff';
  options: Array<
    Omit<CardToCardAccount, 'disabled' | 'method'> & {
      name: CardToCardAccount['method'];
    }
  >;
};

export type AlipayMethod = {
  id: string;
  name: 'alipay';
  account: Omit<AlipayAccount, 'disabled'>;
};

export type WeChatMethod = {
  id: string;
  name: 'wechat';
  account: Omit<WeChatAdminAccount, 'disabled' | 'qrFile'>;
};

export type CashMethod = {
  id: string;
  name: 'cash';
};

export type AvailableMethods =
  | CardToCardMethod
  | AlipayMethod
  | WeChatMethod
  | CashMethod;

type Form = {
  user: User;
  targetCurrency: string;
  amount: string;
  topUpCurrency: string;
  method:
    | (Omit<CardToCardAccount, 'disabled' | 'method'> & {
        name: CardToCardAccount['method'];
      })
    | AlipayMethod
    | WeChatMethod
    | CashMethod;
  publicComment: string;
  privateComment: string;
  currencyRate?: string;
};
type MethodOption = Omit<CardToCardAccount, 'disabled' | 'method'> & {
  name: string;
};
export const TopUpModal: React.FC<TopUpModalProps> = ({ close }) => {
  const { data: currency } = useCurrencyCurrent();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    control,
    resetField,
  } = useForm<Form>({
    defaultValues: {
      topUpCurrency: 'rub',
    },
  });

  const handleSave = (data: Form) => {
    api
      .post('/top-up/admin/create', {
        ...data,
        currencyRate:
          data.topUpCurrency === 'rub' &&
          data.currencyRate &&
          data.currencyRate !== '0'
            ? data.currencyRate
            : undefined,
      })
      .then(() => {
        AlertService.success();
      })
      .then(() => {
        close();
      });
  };

  const [targetCurrency, amount, topUpCurrency] = watch([
    'targetCurrency',
    'amount',
    'topUpCurrency',
  ]);

  const [debouncedAmount, setDebouncedAmount] = React.useState(amount);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAmount(amount);
    }, 250);

    return () => {
      clearTimeout(handler);
    };
  }, [amount]);

  const { data: targetAmount } = useSWR<{ result: string }>(() => {
    if (!targetCurrency) return null;
    if (!topUpCurrency) return null;
    if (typeof debouncedAmount !== 'string' || !isDecimal(debouncedAmount))
      return null;

    return {
      url: '/top-up/calculate',
      params: {
        targetCurrency,
        amount: debouncedAmount,
        topUpCurrency,
      },
    };
  }, fetcher);

  const {
    data: availableMethodsData,
    isValidating: availableMethodsValidating,
  } = useSWR<{ list: AvailableMethods[] }>(
    {
      url: '/top-up/admin/available-methods',
      params: {
        targetCurrency,
        topUpCurrency,
      },
    },
    fetcher,
  );

  const availableMethods = React.useMemo(() => {
    return (availableMethodsData?.list || []).reduce(
      (acc, item) => {
        if (
          item.name === 'sberbank' ||
          item.name === 'alfabank' ||
          item.name === 'tinkoff'
        ) {
          acc.push({
            label: getTopUpMethodTitle(item.name) || item.name,
            options: item.options as MethodOption[],
          });
        } else {
          const otherMethodsIndex = acc.findIndex(
            (method) => method.label === 'Другие пополнения',
          );
          if (otherMethodsIndex !== -1) {
            acc[otherMethodsIndex].options.push(item as MethodOption);
          } else {
            acc.push({
              label: 'Другие пополнения',
              options: [item as MethodOption],
            });
          }
        }
        return acc;
      },
      [] as { label: string; options: MethodOption[] }[],
    );
  }, [availableMethodsData]);

  const availableAmountCurrency = React.useMemo(() => {
    if (!targetCurrency) {
      return [];
    }
    if (targetCurrency === 'usd') {
      return ['usd', 'rub'];
    }
    if (targetCurrency === 'cny') {
      return ['cny', 'rub'];
    }
    throw new Error(`Unknown target currency "${targetCurrency}"`);
  }, [targetCurrency]);

  function calculateTargetAmount() {
    if (watch('topUpCurrency') === 'rub') {
      const amount = watch('amount');
      const rate = watch('currencyRate');
      if (rate && rate !== '0' && amount && amount !== '0') {
        return toMoney(Number(amount) / Number(rate));
      }
    }

    return typeof targetAmount !== 'undefined'
      ? toMoney(targetAmount.result)
      : '';
  }
  const modalRef = useModalClose(close);

  return (
    <div className="modal-dialog" ref={modalRef}>
      <form className="modal-content" onSubmit={handleSubmit(handleSave)}>
        <div className="modal-body">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h3>Добавить пополнение</h3>
            <div className="btn p-0" onClick={close}>
              <i className="bi bi-x fs-36 color-gray-600" />
            </div>
          </div>
          <Controller
            control={control}
            name="user"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Клиент"
                required
                error={fieldState.error?.message}
              >
                <UserSelect placeholder="Клиент" {...field} />
              </InputWrapper>
            )}
          />
          <InputWrapper
            className="mb-3"
            title="Счёт пополнения"
            error={errors.targetCurrency?.message}
            required
          >
            <select
              className="form-select"
              {...register('targetCurrency', {
                ...required,
                onChange() {
                  resetField('topUpCurrency', { defaultValue: 'rub' });
                  resetField('method', { defaultValue: undefined });
                },
              })}
            >
              <option value="" hidden>
                Выберите счет
              </option>
              <option value="usd">USD</option>
              <option value="cny">CNY</option>
            </select>
          </InputWrapper>
          <div className="row mb-3">
            <InputWrapper
              title="Сумма пополнения"
              className="col"
              error={errors.amount?.message}
              required
            >
              <div className="input-group">
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  step={0.01}
                  {...register('amount', {
                    ...required,
                    ...decimalValidation,
                  })}
                />
              </div>
            </InputWrapper>
            <InputWrapper
              className="col-3"
              title="Валюта"
              error={errors.topUpCurrency?.message}
              required
            >
              <select
                className="form-select"
                {...register('topUpCurrency', {
                  ...required,
                  onChange() {
                    resetField('method', { defaultValue: undefined });
                  },
                })}
              >
                {availableAmountCurrency.map((currency) => (
                  <option
                    key={currency}
                    value={currency}
                    children={getCurrencySymbol(currency)}
                  />
                ))}
              </select>
            </InputWrapper>
          </div>
          <InputWrapper
            title="Курс"
            className="col"
            error={errors.currencyRate?.message}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={0}
                step={0.01}
                {...register('currencyRate', {
                  ...decimalValidation,
                })}
                disabled={watch('topUpCurrency') !== 'rub'}
              />
            </div>
            <div className="fs-14 color-gray-400 mb-2">
              Если не указать, будет использован текущий курс
            </div>
          </InputWrapper>

          {currency ? (
            <div className="bg-gray p-3 rounded-8 mb-3">
              <div>
                Текущий курс Сайт: {getCurrencySymbol('cny')} ={' '}
                {toMoney(currency.cnyRubInternal, 'rub')}
              </div>
              <div>
                Текущий курс Сайт: $ = {toMoney(currency.usdRubInternal)} ₽
              </div>
            </div>
          ) : null}

          <Controller
            control={control}
            name="method"
            rules={{
              ...required,
            }}
            render={({ field, fieldState }) => (
              <InputWrapper
                className="mb-3"
                title="Способ пополнения"
                required
                error={fieldState.error?.message}
              >
                <ReactSelect
                  placeholder="Способ пополнения"
                  isLoading={availableMethodsValidating}
                  options={availableMethods}
                  getOptionLabel={(option) => {
                    if (
                      option.name === 'sberbank' ||
                      option.name === 'alfabank' ||
                      option.name === 'tinkoff'
                    ) {
                      return `${option.cardNumber} / ${
                        option.cardHolderName
                      } / ${toMoney(option.remainingLimit)} из ${toMoney(
                        option.limit,
                      )} ₽`;
                    }

                    return getTopUpMethodTitle(option.name) || option.name;
                  }}
                  getOptionValue={(option) => option.id ?? option.name}
                  {...field}
                />
              </InputWrapper>
            )}
          />
          <InputWrapper title="Сумма зачисления" className="mb-3">
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={calculateTargetAmount()}
                disabled
              />
              {targetCurrency && (
                <span className="input-group-text">
                  {getCurrencySymbol(targetCurrency)}
                </span>
              )}
            </div>
          </InputWrapper>
          <InputWrapper
            title="Комментарий для клиента"
            className="mb-3"
            error={errors.publicComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для клиента"
              className="form-control fs-16 lh-sm"
              {...register('publicComment', { ...required })}
            />
          </InputWrapper>
          <InputWrapper
            title="Комментарий для администратора"
            error={errors.privateComment?.message}
            required
          >
            <textarea
              placeholder="Укажите здесь дополнительную информацию для администратора"
              className="form-control fs-16 lh-sm"
              {...register('privateComment', { ...required })}
            />
          </InputWrapper>
        </div>
        <div className="modal-footer justify-content-end">
          <button type="submit" className="btn btn-success">
            Добавить
          </button>
        </div>
      </form>
    </div>
  );
};
