import React from 'react';
import { NavLink } from 'react-router-dom';
import { StatusBlock } from '../../common/status-block';
import moment from 'moment';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { FormattedMessage, useIntl } from 'react-intl';

type ParcelStatusTableProps = {
  parcel: CleanPurchaseOrder;
};

export const ParcelStatusTable: React.FC<ParcelStatusTableProps> = ({
  parcel,
}) => {
  const intl = useIntl();
  return (
    <ul className="d-flex flex-column p-0 mb-0">
      {parcel.status !== 'canceled' && (
        <StatusBlock
          title={
            parcel.status === 'onTheWayToStock' ? (
              <FormattedMessage
                defaultMessage="В пути на склад в Китай"
                id="order.status-label.inTransitToAWarehouseInChina"
                description="Статус заказа"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Прибыл на склад в Китае"
                id="order.status-label.arrivedAtWarehouseInChina"
                description="Статус заказа"
              />
            )
          }
          subtitle={
            parcel.receiptDate &&
            moment(parcel.receiptDate).format('DD.MM.YYYY, HH:mm')
          }
          status={
            parcel.status === 'onTheWayToStock'
              ? 'active'
              : parcel.receiptDate
                ? 'done'
                : undefined
          }
          connector={true}
          key={'receiptDate'}
        />
      )}

      {parcel.status !== 'canceled' && (
        <StatusBlock
          title={
            parcel.status === 'receivedInStock' ? (
              <FormattedMessage
                defaultMessage="Готов к отправке в Россию"
                id="order.status-label.readyForShipmentToRussia"
                description="Статус заказа"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Передан на доставку в РФ"
                id="parcelStatus.status-label.transferredForShippingToRussia"
                description="Статус заказа"
              />
            )
          }
          subtitle={
            parcel.deliveryOrder ? (
              <>
                <span>
                  <FormattedMessage
                    defaultMessage="Доставка — "
                    id="parcelStatus.status-label.shipping"
                    description="Подзаголовок на странице"
                  />
                </span>
                <NavLink
                  className="btn-link cursor-pointer text-decoration-underline"
                  to={`/deliveries/delivery-order/${parcel.deliveryOrder.id}`}
                >{`D${parcel.deliveryOrder.internalId}`}</NavLink>
              </>
            ) : undefined
          }
          status={
            parcel.status === 'receivedInStock'
              ? 'active'
              : parcel.status === 'sentToRussia'
                ? 'done'
                : undefined
          }
          connector={false}
          key={'sentToRussia'}
        />
      )}

      {parcel.status === 'canceled' && (
        <StatusBlock
          title={intl.formatMessage({
            defaultMessage: 'Отменен',
            id: 'order.status-label.canceled',
            description: 'Статус заказа',
          })}
          subtitle={
            <>
              {parcel.cancelledAt && (
                <div>
                  {moment(parcel.cancelledAt).format('DD.MM.YYYY, HH:mm')}
                </div>
              )}
              <div>{parcel.cancelReason}</div>
            </>
          }
          status={'canceled'}
          connector={false}
          key={'canceled'}
        />
      )}
    </ul>
  );
};
