import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  decimalValidation,
  intValidation,
  required,
} from '../../../utils/validations';
import { AlertService } from '../../../services/alert.service';
import { useOrderCategoriesList } from '../../../consts/db-values';
import { api } from '../../../services/api';
import { InputWrapper } from '../../common/input-wrapper';
import { MediaInput, MediaInputValue } from '../../common/media-input';
import * as Sentry from '@sentry/react';
import { FormattedMessage, useIntl } from 'react-intl';
import '../../../sass/purchase-order-receipt-confirmation-modal.scss';
import { CleanPurchaseOrder } from '../../../export-types/cleaned-types';
import { PurchaseOrderConfirmationModalClientOrders } from './purchase-order-confirmation-modal-client-orders';
import { useStorageLocation } from '../../../api/delivery';
import CreatableSelect from 'react-select/creatable';

type PurchaseOrderReceiptConfirmationModalProps = {
  close: () => void;
  reject: () => void;
  orderId: string;
  internalId?: number | null;
  userInternalId?: number | null;
};

export type PurchaseOrderCategory =
  | 'householdGoods'
  | 'smallHouseholdGoods'
  | 'clothes';

type Form = {
  places: string;
  count: string;
  weight: string;
  volume: string;
  productFiles: MediaInputValue[];
  category: PurchaseOrderCategory;
  storageLocation: { value: string };
  receiptComment: string;
};
export const PurchaseOrderReceiptConfirmationModal: React.FC<
  PurchaseOrderReceiptConfirmationModalProps
> = (props) => {
  const { data: storageLocations } = useStorageLocation();

  const { register, formState, handleSubmit, watch, control } = useForm<Form>();

  const intl = useIntl();
  // const ORDER_CATEGORIES_LIST = useOrderCategoriesList();
  const ORDER_CATEGORIES_LIST = useOrderCategoriesList().filter(
    (category) => category.value !== 'householdGoods',
  );

  const [btnSubmitDisabled, setBtnSubmitDisabled] = useState<boolean>(false);
  const productFiles = watch('productFiles');
  const [updateFilesResponse, setUpdateFilesResponse] = useState<
    MediaInputValue[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputStorage, setInputStorage] = useState('');

  useEffect(() => {
    if (productFiles === undefined) {
      setBtnSubmitDisabled(false);
      return;
    }

    const processFiles = async () => {
      let filesResponse = [];

      for (const file of productFiles) {
        setBtnSubmitDisabled(true);

        if (file?.type === 'new' && file.uploading) {
          setLoading(true);

          try {
            const response = await file.uploading;
            if (response.file.id) {
              filesResponse.push({
                type: 'exist' as const,
                file: response.file,
              });
            } else {
              filesResponse.push({
                type: file.type,
                file: file.file,
              });
            }
          } catch (e) {
            filesResponse.push({
              type: file.type,
              file: file.file,
            });
            Sentry.captureException(e, {
              extra: { debug: 'PurchaseOrderReceiptConfirmationModal: файлы' },
            });
          }
        } else if (file?.type === 'exist') {
          filesResponse.push({
            type: file.type,
            file: file.file,
          });
        }
        setLoading(false);
      }

      setUpdateFilesResponse(filesResponse);

      const hasMissingId = filesResponse.some(
        (file) => file.type !== 'exist' || !file.file || !file.file.id,
      );
      if (hasMissingId) {
        setBtnSubmitDisabled(true);
      } else {
        setBtnSubmitDisabled(false);
      }
    };

    processFiles();
  }, [productFiles]);

  const onSubmit = handleSubmit(async (data) => {
    const body = {
      places: Number(data.places),
      count: data.count ? Number(data.count) : null,
      weight: data.weight,
      volume: data.volume,
      category: data.category,
      productFiles: updateFilesResponse
        .filter(
          (item): item is typeof item & { type: 'exist' } =>
            item.type === 'exist',
        )
        .map((response) => ({ id: response.file.id })),
      storageLocation: data.storageLocation.value,
      receiptComment: data.receiptComment,
    };
    try {
      await api.post<CleanPurchaseOrder>(
        `/purchase-order/v2/${props.orderId}/receipt-confirmation`,
        body,
      );
    } catch (e) {
      Sentry.captureException(e, {
        extra: { debug: 'PurchaseOrderReceiptConfirmationModal: api', body },
      });
      return;
    }

    AlertService.success();
    props.close();
  });

  return (
    <div className="modal-dialog">
      <form className="modal-content" onSubmit={onSubmit}>
        <div className="modal-header border-bottom-0 p-4">
          <h3>
            <FormattedMessage
              defaultMessage="Подтвердить получение на склад"
              id="order.status-label.confirmReceiptToWarehouse"
              description="Подтверждение получения"
            />
          </h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.reject()}
          />
        </div>

        <div className="modal-body pt-2 p-4">
          <h4 className="mt-3">
            <FormattedMessage
              defaultMessage="Выкуп: V{value}"
              id="purchaseOrderModal.label.purchaseNumberInfo"
              description="Подзаголовок модального окна"
              values={{
                value: props.internalId,
              }}
            />
          </h4>
          <Controller
            control={control}
            name="productFiles"
            rules={{ ...required }}
            render={({ field, fieldState }) => (
              <InputWrapper
                title={intl.formatMessage({
                  defaultMessage: 'Фотографии товаров при получении',
                  id: 'input.label.photosOfGoodsOnReceipt',
                  description: 'Заголовок поля ввода',
                })}
                className="mb-3"
                error={fieldState.error?.message}
                required
              >
                <MediaInput
                  {...field}
                  multiple
                  enableUploadOnAttach
                  loading={loading}
                  setLoading={setLoading}
                  updateFilesResponse={updateFilesResponse}
                />
              </InputWrapper>
            )}
          />
          <div className="mt-3">
            <div className="row align-items-end">
              <div className="col-4">
                <InputWrapper
                  title={intl.formatMessage({
                    defaultMessage: 'Кол-во мест',
                    id: 'deliveryAssemblyModal.input-label.amountOfPlaces',
                    description: 'Заголовок поля ввода',
                  })}
                  required
                  error={formState.errors.places?.message}
                >
                  <input
                    type="text"
                    className="form-control"
                    {...register('places', {
                      ...required,
                      ...intValidation,
                    })}
                  />
                </InputWrapper>
              </div>
              <div className="col-4">
                <InputWrapper
                  theme="light"
                  title={intl.formatMessage({
                    defaultMessage: 'Вес',
                    id: 'input.label.weight',
                    description: 'Заголовок поля ввода',
                  })}
                  error={formState.errors.weight?.message}
                  required
                >
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      step={0.01}
                      {...register('weight', {
                        ...required,
                        ...decimalValidation,
                      })}
                    />
                    <span className="input-group-text">
                      <FormattedMessage
                        defaultMessage="кг"
                        id="input.label.kg"
                        description="Единица измерения"
                      />
                    </span>
                  </div>
                </InputWrapper>
              </div>
              <div className="col-4">
                <InputWrapper
                  theme="light"
                  title={intl.formatMessage({
                    defaultMessage: 'Объём',
                    id: 'input.label.volume',
                    description: 'Заголовок поля ввода',
                  })}
                  error={formState.errors.volume?.message}
                  required
                >
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      step={0.01}
                      {...register('volume', {
                        ...required,
                        ...decimalValidation,
                      })}
                    />
                    <span className="input-group-text">
                      <FormattedMessage
                        defaultMessage="м³"
                        id="input.label.length"
                        description="Единица измерения"
                      />
                    </span>
                  </div>
                </InputWrapper>
              </div>
            </div>
          </div>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Кол-во товара',
              id: 'input.label.quantityOfGoods',
              description: 'Заголовок поля ввода',
            })}
          >
            <div className="input-group">
              <input
                className="form-control"
                type="number"
                min={1}
                step={1}
                {...register('count')}
              />
              <span className="input-group-text">
                <FormattedMessage
                  defaultMessage="шт"
                  id="input.label.pcs"
                  description="Единица измерения"
                />
              </span>
            </div>
          </InputWrapper>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Категория товара',
              id: 'input.input-label.goodsCategory',
              description: 'Заголовок поля ввода',
            })}
            error={formState.errors.category?.message}
            required
          >
            <select
              className="form-select"
              {...register('category', { ...required })}
            >
              <option value="" hidden>
                <FormattedMessage
                  defaultMessage="Выберите категорию товара"
                  id="purchaseOrderModal.placeholder-label.selectAGoodsCategory"
                  description="Заголовок выпадающего меню"
                />
              </option>
              {ORDER_CATEGORIES_LIST.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </InputWrapper>
          <InputWrapper
            theme="light"
            className="mt-3"
            title={intl.formatMessage({
              defaultMessage: 'Место хранения',
              id: 'storage.label.storagePlace',
              description: 'Информация о месте хранения',
            })}
            error={formState.errors.storageLocation?.message}
            required
          >
            <Controller
              name="storageLocation"
              control={control}
              rules={{
                ...required,
                maxLength: {
                  value: 20,
                  message: 'Максимальная длина 20 символов',
                },
              }}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  options={storageLocations?.map((location) => ({
                    value: location,
                    label: location,
                  }))}
                  placeholder=""
                  formatCreateLabel={(value) => `Добавить "${value}"`}
                  onInputChange={(newValue) => {
                    setInputStorage(newValue);
                  }}
                  onChange={(newValue) => {
                    setInputStorage('');
                    field.onChange(newValue);
                  }}
                  onBlur={() => {
                    if (inputStorage) {
                      field.onChange({
                        value: inputStorage,
                        label: inputStorage,
                      });
                    }
                  }}
                />
              )}
            />
          </InputWrapper>
          <InputWrapper
            theme="light"
            title={intl.formatMessage({
              defaultMessage: 'Комментарий',
              id: 'input.input-label.receiptComment',
              description: 'Заголовок поля ввода',
            })}
            className="mt-3"
            error={formState.errors.receiptComment?.message}
          >
            <div className="input-group">
              <textarea
                className="form-control"
                {...register('receiptComment')}
              />
            </div>
          </InputWrapper>
        </div>
        <div className="col">
          <PurchaseOrderConfirmationModalClientOrders
            user={props.userInternalId ?? 0}
          />
        </div>
        <div className="modal-footer justify-content-end">
          <button
            type="submit"
            className="btn btn-success"
            disabled={formState.isSubmitting || btnSubmitDisabled}
          >
            {formState.isSubmitting ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Подтвердить получение"
                id="purchaseOrderModal.btn-label.acknowledgeReceipt"
                description="Надпись на кнопке"
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
