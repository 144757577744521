import React from 'react';
import { NavLink } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { OutletContextParams } from './admin-delivery-orders-screen';
import { useIntl } from 'react-intl';
import { useRbac } from '../../common/Rbac';
import { RbacObject } from '../../../export-types/cleaned-types';
import { useProfile } from '../../../api/profile';
import { userIsSupplier } from '../../../utils/common.utils';

type DeliveryOrderProps = {
  status:
    | 'processing'
    | 'assembly'
    | 'sentToRussia'
    | 'receivedInRussia'
    | 'receivedInRetailRussia'
    | 'sentToRecipient'
    | 'delivered';
};

export const DeliveryOrdersTabs = ({
  debouncedInternalId,
  linked,
  selectSupplier,
}: OutletContextParams) => {
  const intl = useIntl();
  const { hasPermission } = useRbac();
  const { data: user } = useProfile();
  const canRead = hasPermission(RbacObject.DeliveryOrder, 'read');
  const canReadTransit =
    canRead || hasPermission(RbacObject.DeliveryOrder, 'read:transit');
  const tabs = [
    ...(canRead && !userIsSupplier(user)
      ? [
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.processing',
              defaultMessage: 'Заморожен',
              description: 'Надпись на вкладке',
            }),
            path: 'processing',
            name: 'processing' as const,
          },
        ]
      : []),
    ...(canRead
      ? [
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.onPicking',
              defaultMessage: 'На комплектации',
              description: 'Надпись на вкладке',
            }),
            path: 'assembly',
            name: 'assembly' as const,
          },
        ]
      : []),
    ...(canReadTransit
      ? [
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.InTransitToAWarehouseInRussia',
              defaultMessage: 'В пути на склад в РФ',
              description: 'Надпись на вкладке',
            }),
            path: 'sent-to-russia',
            name: 'sentToRussia' as const,
          },
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.inTheWarehouseInRussia',
              defaultMessage: 'На складе в РФ',
              description: 'Надпись на вкладке',
            }),
            path: 'received-in-russia',
            name: 'receivedInRussia' as const,
          },
          {
            title: intl.formatMessage({
              id: 'TransactiosHistrory.lable.receivedInRetailRussia',
              defaultMessage: 'На складе в РФ (Розница)',
              description: 'Надпись на кнопке/вкладке',
            }),
            path: 'received-in-retail-russia',
            name: 'receivedInRetailRussia' as const,
          },
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.transferredToTheShippingCompany',
              defaultMessage: 'Переданые в ТК',
              description: 'Надпись на вкладке',
            }),
            path: 'sent-to-recipient',
            name: 'sentToRecipient' as const,
          },
        ]
      : []),
    ...(canRead
      ? [
          {
            title: intl.formatMessage({
              id: 'deliveryOrders.tab-label.received',
              defaultMessage: 'Полученные',
              description: 'Надпись на вкладке',
            }),
            path: 'delivered',
            name: 'delivered' as const,
          },
        ]
      : []),
  ];
  const { data, isLoading } = useSWR<{
    items: Record<DeliveryOrderProps['status'], number | undefined>;
  }>(
    {
      url: '/delivery-order/total-by-status',
      params: {
        linked,
        withoutComposite: true,
        internalId: debouncedInternalId ? debouncedInternalId : undefined,
        supplierId: selectSupplier,
      },
    },
    fetcher,
  );

  const count = React.useMemo(() => {
    if (!data) {
      return {};
    }

    return {
      processing: data.items.processing || 0,
      assembly: data.items.assembly || 0,
      sentToRussia: data.items.sentToRussia || 0,
      receivedInRussia: data.items.receivedInRussia || 0,
      receivedInRetailRussia: data.items.receivedInRetailRussia || 0,
      sentToRecipient: data.items.sentToRecipient || 0,
      delivered: data.items.delivered || 0,
    };
  }, [data]);

  function renderTabCount(
    item: typeof tabs extends Array<infer T> ? T : never,
  ) {
    if (isLoading) {
      return (
        <>
          (<i className="bi bi-three-dots color-gray-400" />)
        </>
      );
    }

    if (typeof count[item.name] === 'undefined') {
      return null;
    }

    return `(${count[item.name]})`;
  }

  return (
    <ul className="nav nav-pills d-inline-flex">
      {tabs.map((item) => (
        <li className="nav-item" key={item.title}>
          <NavLink to={item.path} className="nav-link text-decoration-none">
            {item.title} {renderTabCount(item)}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
