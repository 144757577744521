import { Address } from '../../api/delivery';
import { useIntl } from 'react-intl';
import { WhisperSpeaker } from '../../utils/whisper-speaker';
import { ModalsService } from '../../services/modals.service';
import { DeliveryAddressService } from '../../services/delivery-address.service';
import { AlertService } from '../../services/alert.service';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import Whisper from 'rsuite/Whisper';
import IconButton from 'rsuite/IconButton';
import React from 'react';
import { AddressFormModal } from '../screens/delivery/address-form-modal.component';

export const AddressMenu = ({
  address,
  internalId,
  mutate,
}: {
  address: Address;
  internalId: number;
  mutate: () => void;
}) => {
  const intl = useIntl();

  const renderMenu =
    (): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = async (eventKey: string | undefined) => {
        onClose();
        if (eventKey === 'edit') {
          return ModalsService.createModal(AddressFormModal, {
            onSubmit: async (form) => {
              await DeliveryAddressService.updateDeliveryAddress(
                form,
                internalId as number,
                address.id,
              ).then(() => {
                mutate();
                AlertService.success(
                  intl.formatMessage({
                    defaultMessage: 'Адрес и получатель обновлены',
                    id: 'createOrder.label.addressAndRecipientUpdated',
                    description: 'Информация о заказе',
                  }),
                );
              });
            },
            defaultValues: address,
          });
        }
        if (eventKey === 'delete') {
          const result = window.confirm(
            'Вы действительно хотите удалить адрес у данного пользователя?',
          );
          if (!result) {
            return;
          }
          DeliveryAddressService.deleteDeliveryAddress(
            internalId,
            address.id,
          ).then(() => {
            AlertService.success();
            mutate();
          });
          return;
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item eventKey={'edit'}>
              <i className="bi bi-pencil" />
              <span className="ps-2">Изменить</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'delete'}>
              <i className="bi bi-trash" />
              <span className="ps-2">Удалить</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  return (
    <Whisper placement="leftStart" trigger="click" speaker={renderMenu()}>
      <IconButton
        appearance="subtle"
        icon={<i className="bi bi-three-dots" />}
      />
    </Whisper>
  );
};
