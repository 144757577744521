import React from 'react';
import { CleanConversation } from '../../../export-types/cleaned-types';
import { IdCell } from '../../common/cards/id-cell-card.component';
import moment from 'moment';
import { WhisperSpeaker } from '../../../utils/whisper-speaker';
import Popover from 'rsuite/Popover';
import Dropdown from 'rsuite/Dropdown';
import IconButton from 'rsuite/IconButton';
import Whisper from 'rsuite/Whisper';
import { api } from '../../../services/api';
import classNames from 'classnames';
import { Manager } from '../../common/cards/manager-cell-card.component';

export type ConversationStatus =
  | 'unanswered'
  | 'refund'
  | 'debtor'
  | 'noted'
  | 'archive'
  | 'unread'
  | 'defectCheck'
  | 'delayed'
  | 'adminAssistance';

export type ConversationItemProps = {
  onClick: (data: CleanConversation) => void;
  onChange?: (data: CleanConversation) => void;
  pinned: boolean;
  data: CleanConversation;
};

export function ConversationItem(props: ConversationItemProps) {
  const renderMenu =
    (): WhisperSpeaker =>
    ({ onClose, left, top, className }, ref) => {
      const handleSelect = async (
        eventKey: string | undefined,
        event: React.SyntheticEvent,
      ) => {
        event.stopPropagation();
        onClose();
        if (eventKey === '1') {
          await api.post(`/conversation/pin`, undefined, {
            params: {
              conversationId: props.data.id,
            },
          });
          props.onChange && props.onChange(props.data);
        } else {
          await api.post(
            `/conversation/status`,
            {
              status: eventKey !== 'remove' ? eventKey : null,
            },
            {
              params: {
                conversationId: props.data.id,
              },
            },
          );
          props.onChange && props.onChange(props.data);
        }
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            {!props.pinned ? (
              <Dropdown.Item eventKey={'1'}>
                <i className="bi bi-pin" />
                <span className="ps-2">Закрепить</span>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item eventKey={'1'}>
                <i className="bi bi-pin" />
                <span className="ps-2">Открепить</span>
              </Dropdown.Item>
            )}
            <Dropdown.Item eventKey={'unread'}>
              <span className="ps-2">Пометить "Не прочитано"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'refund'}>
              <span className="ps-2">Пометить "Возврат"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'defectCheck'}>
              <span className="ps-2">Пометить "Проверка на брак"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'adminAssistance'}>
              <span className="ps-2">Пометить "Помощь Админа"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'delayed'}>
              <span className="ps-2">Пометить "Задержка"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'archive'}>
              <span className="ps-2">Пометить "Архив"</span>
            </Dropdown.Item>
            <Dropdown.Item eventKey={'remove'}>
              <span className="ps-2">Убрать отметку</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };

  return (
    <div
      className="row cursor-pointer g-0 border-bottom rounded"
      onClick={() => {
        props.onClick(props.data);
      }}
    >
      <div className="col-2 d-flex align-items-center ">
        <div className=" p-2">
          {props.data.type === 'supplier' ? (
            <div className="fw-600">
              Поставщик{' '}
              {props.data.deliveryOrder?.supplier?.name ??
                props.data.purchaseOrder?.supplier?.name ??
                ''}
            </div>
          ) : (
            <div className="fw-600">
              Клиент{' '}
              {props.data.deliveryOrder?.user?.internalId ??
                props.data.purchaseOrder?.user?.internalId ??
                ''}
            </div>
          )}
        </div>
      </div>
      <div className="col-6 row">
        <div className="p-2">
          <div className="fs-18 fw-600">
            {props.data.purchaseOrder ? (
              <>
                Заказ{' '}
                <IdCell
                  internalId={props.data.purchaseOrder.internalId}
                  type={props.data.purchaseOrder.type}
                />{' '}
                - {props.data.purchaseOrder.name}
              </>
            ) : (
              props.data.deliveryOrder && (
                <>
                  Доставка{' '}
                  <IdCell
                    internalId={props.data.deliveryOrder.internalId}
                    type="delivery"
                  />
                </>
              )
            )}
          </div>
          <div className="color-gray-450 text-truncate">
            {props.data.lastMessage?.message}
          </div>
        </div>
      </div>
      <div className="col-1 p-2">
        {props.data.purchaseOrder?.user?.manager ? (
          <Manager manager={props.data.purchaseOrder?.user?.manager} />
        ) : props.data.deliveryOrder?.user?.manager ? (
          <Manager manager={props.data.deliveryOrder?.user?.manager} />
        ) : null}
      </div>
      <div className="col-3 text-center d-flex align-items-center gap-1 justify-content-end">
        {props.data.unread > 0 && (
          <span className="badge bg-green-500">{props.data.unread}</span>
        )}
        <div className="color-gray-450">
          {moment(props.data.lastMessage?.sentAt).format('HH:mm')}
        </div>
        <i
          className={classNames(
            'bi bi-pin mx-2',
            props.pinned ? 'color-green' : '',
          )}
        />
        <Whisper
          placement="leftStart"
          trigger="click"
          onClick={(e) => e.stopPropagation()}
          speaker={renderMenu()}
        >
          <IconButton
            appearance="subtle"
            icon={<i className="bi bi-three-dots" />}
          />
        </Whisper>
      </div>
    </div>
  );
}
