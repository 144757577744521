import React from 'react';
import { toMoney } from '../../../utils/common.utils';
import { useBalance } from '../../../api/finances';
import { CleanMoneyBill } from '../../../export-types/cleaned-types';

function renderBalance(
  key: string | number,
  title: string,
  moneyBill: CleanMoneyBill,
) {
  return (
    <div className="col-4" key={key}>
      <div className="">{title}</div>
      <div className="row">
        <div className="col-auto">
          <div className="p-3 rounded score-item cny d-flex flex-column justify-content-between mw-200">
            <div className="d-flex flex-row justify-content-between">
              <div className="fs-14">CNY</div>
              <div className="score-item__sign">¥</div>
            </div>
            <div className="text-start fs-24 text-black">
              {toMoney(moneyBill.cny, 'cny')}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="p-3 rounded score-item usd d-flex flex-column justify-content-between mw-200">
            <div className="d-flex flex-row justify-content-between">
              <div className="fs-14">USD</div>
              <div className="score-item__sign">$</div>
            </div>
            <div className="text-start fs-24 text-black">
              {toMoney(moneyBill.usd)} $
            </div>
          </div>
        </div>
        {/*<div className="col-auto">*/}
        {/*  <div className="p-3 rounded score-item rub d-flex flex-column justify-content-between mw-200">*/}
        {/*    <div className="d-flex flex-row justify-content-between">*/}
        {/*      <div className="fs-14">RUB</div>*/}
        {/*      <div className="score-item__sign">₽</div>*/}
        {/*    </div>*/}
        {/*    <div className="text-start fs-24 text-black">*/}
        {/*      {toMoney(moneyBill?.rub || 0)} ₽*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export const MoneyBillsComponent = () => {
  const { data: balance } = useBalance();
  if (!balance) {
    return null;
  }

  return (
    <div className="row mb-5">
      {renderBalance(
        'common',
        balance.suppliers && balance.suppliers.length > 1 ? 'Общий баланс' : '',
        balance,
      )}
      {balance.suppliers && balance.suppliers.length > 1
        ? balance.suppliers.map((supplier) =>
            renderBalance(supplier.id, supplier.name, supplier.balance),
          )
        : null}
    </div>
  );
};
