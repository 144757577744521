import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../../services/api';
import { toMoney } from '../../../utils/common.utils';
import { RbacObject } from '../../../export-types/cleaned-types';
import { Rbac } from '../../common/Rbac';

type CompositeDeliveryStatus = 'assembly' | 'sentToRussia' | 'receivedInRussia';

type CompositeDelivery = {
  id: string;
  status: CompositeDeliveryStatus;

  deliveryPrice: string;
  insurancePrice: string;
  packagePrice: string;
  markupPrice: string;
  prePrice: string;

  moderateDeliveryPrice?: string;
  moderateInsurancePrice?: string;
  moderatePackagePrice?: string;
  moderateMarkupPrice?: string;
  price?: string;

  expenses?: {
    client: string;
    company: string;
  };
};

function ClientExpensesTable(props: {
  preTotal: string;
  total?: string;
  actual?: string;
}) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>
            <div>Итого</div>
            <div className="color-gray-400 fw-400">К оплате клиенту</div>
          </td>
          <td className="text-end" style={{ width: '85px' }}>
            {toMoney(props.preTotal)} $
          </td>
          <td className="text-end" style={{ width: '140px' }}>
            {props.total ? (
              `${toMoney(props.total)} $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>Итого оплачено клиентом</td>
          <td colSpan={2} className="text-end">
            {props.actual ? (
              `${toMoney(props.actual)}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyExpensesTable(props: { total?: string; actual?: string }) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>
            <div>Фактические затраты</div>
            <div className="fw-400 color-gray-400">
              на доставку, страховку и упаковку
            </div>
          </td>
          <td className="text-end">
            {props.total ? (
              `${toMoney(props.total)}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
        <tr>
          <td>
            <div>Фактических затрат оплачено</div>
          </td>
          <td className="text-end">
            {props.actual ? (
              `${toMoney(props.actual)}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function CompanyProfitTable(props: { order: CompositeDelivery }) {
  return (
    <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
      <tbody>
        <tr>
          <td>Прибыль по заказу</td>
          <td className="text-end">
            {props.order.moderateMarkupPrice ? (
              `${toMoney(Number(props.order.moderateMarkupPrice))}  $`
            ) : (
              <i className="bi bi-three-dots color-gray-400" />
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

type DeliveryOrderFinancesInfoProps = {
  id: string;
};

export const CompositeDeliveryFinancesInfo: React.FC<
  DeliveryOrderFinancesInfoProps
> = ({ id }) => {
  const { data: order } = useSWR<CompositeDelivery>(
    `/composite-delivery/${id}`,
    fetcher,
  );

  const threeDots = <i className="bi bi-three-dots color-gray-400" />;

  if (!order) {
    return null;
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <div className="fs-18 fw-600 color-gray-450">Финансы</div>
      </div>
      <table className="table table-xs table-borderless mt-3 fw-bold align-middle">
        <thead>
          <tr>
            <th className="color-gray-400">Позиция</th>
            <th className="color-gray-400 text-end" style={{ width: '85px' }}>
              Предв.
            </th>
            <th className="color-gray-400 text-end" style={{ width: '140px' }}>
              Факт
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div>Доставка Китай — РФ</div>
            </td>
            <td className="text-end">{toMoney(order.deliveryPrice)} $</td>
            <td className="text-end">
              {order.moderateDeliveryPrice
                ? `${order.moderateDeliveryPrice} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>Страховой сбор</td>
            <td className="text-end">{toMoney(order.insurancePrice)} $</td>
            <td className="text-end">
              {order.moderateInsurancePrice
                ? `${toMoney(order.moderateInsurancePrice)} $`
                : threeDots}
            </td>
          </tr>
          <tr>
            <td>
              <div>Упаковка</div>
            </td>
            <td className="text-end">{toMoney(order.packagePrice)} $</td>
            <td className="text-end">
              {order.moderatePackagePrice
                ? `${toMoney(order.moderatePackagePrice)} $`
                : threeDots}
            </td>
          </tr>

          <tr>
            <td>Комиссия за доставку</td>
            <td className="text-end">{toMoney(order.markupPrice)} $</td>
            <td className="text-end">
              {order.moderateMarkupPrice
                ? `${toMoney(order.moderateMarkupPrice)} $`
                : threeDots}
            </td>
          </tr>
        </tbody>
      </table>
      <hr className="mt-3" />
      <ClientExpensesTable
        preTotal={order.prePrice}
        total={order.price}
        actual={order.expenses?.client}
      />
      <Rbac object={RbacObject.DeliveryOrder} action={'read:finances-company'}>
        <hr className="mt-3" />
        <CompanyExpensesTable
          total={
            order.price
              ? (
                  Number(order.price) - Number(order.moderateMarkupPrice)
                ).toString()
              : undefined
          }
          actual={order.expenses?.company}
        />
        <hr className="mt-3" />
        <CompanyProfitTable order={order} />
      </Rbac>
    </div>
  );
};
